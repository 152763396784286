import React, { useState, useRef, useEffect } from "react";
import TitlePage from "../../component/Desktop/titlePage";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import RowInput4 from "../../component/Desktop/rowInput4";
import Loading from "../../component/Desktop/loading/loading";
import {
  KEY,
  lang,
  COMMON_CONSTANT,
  STYLE,
} from "../../constants/common.const";
import BreakLine from "../../component/Desktop/breakLine";
import {
  MSG_CATCH,
  showMessage,
  getDateValue,
  statusRes,
  renderOptionCanInputSearch,
  int2DateString,
  getTypeAndFileName,
  getAttachName,
} from "../../constants/utils";
import { useHistory } from "react-router-dom";
import {
  RollbackOutlined,
  SaveOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import API from "../../api/backend/contractParent";
import RepresentativeAPI from "../../api/backend/representativeMaster";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Col,
  Row,
  Space,
  Button,
  Input,
  DatePicker,
  Modal,
  Form,
  Upload,
  Switch,
  Select,
  Checkbox,
} from "antd";
import classText from "../../models/control/text";
import FooterPage from "../../component/footer/footerPage";
import classPopup from "../../models/control/popup";
import PopupCusLocaName from "./popupCusLocaName";
import classGrid from "../../models/control/grid";
import { config, getToken } from "../../api/config";
import axios from "axios";
import classDdl from "../../models/control/dropdownlist";
import APIUser from "../../api/backend/auth";
import { formatNumber } from "../../utils/helper";

function EditContractParent({ history: historyPage }) {
  let history = useHistory();
  let [txtDepartmentName, setTxtDepartmentName] = useState(new classText()); //Required
  let txtConstructionNameEl = useRef(null);
  let [txtConstructionName, setTxtConstructionName] = useState(new classText()); //Required
  let txtStartDate1Ref = useRef(null);
  let [txtStartDate1, setTxtStartDate1] = useState(new classText());
  let [txtEndDate2, settxtEndDate2] = useState(new classText());
  let [txthm20_TRHADRS2, setTxthm20_TRHADRS2] = useState(""); //Required
  let [txthm20_TRHADRS3, setTxthm20_TRHADRS3] = useState(""); //Required
  let [txthm20_TRHADRS2_OLD, setTxthm20_TRHADRS2_OLD] = useState(""); //Required
  let [txthm20_TRHADRS3_OLD, setTxthm20_TRHADRS3_OLD] = useState(""); //Required
  let [locationName, setLocationName] = useState(""); //Required
  let [txthm20_TRHADRS1, settxthm20_TRHADRS1] = useState(""); //Required
  let [txthm20_TRHADRS1_OLD, setTxthm20_TRHADRS1_OLD] = useState(""); //Required
  let [txthm20_TRHSAKNM1, settxthm20_TRHSAKNM1] = useState(""); //Required
  let [txtNnyskicd, settxtNnyskicd] = useState("");
  let [txtjyuskicd, settxtjyuskicd] = useState("");
  let [txtOfficeName, setTxtOfficeName] = useState("");
  let [txtRepresentativeName, setxtRepresentativeName] = useState("");
  let [txtOrderAmount, setOrderAmount] = useState(0);

  const [allowSyncDepartmentName, setAllowSyncDepartmentName] = useState(false);
  const [allowSyncConstructionName, setAllowSyncConstructionName] =
    useState(false);
  const [allowSyncLocationName, setAllowSyncLocationName] = useState(false);
  const [allowSyncContractDate, setAllowSyncContractDate] = useState(false);
  const [allowSyncOrdererName, setAllowSyncOrdererName] = useState(false);
  const [allowSyncFinishedDate, setAllowSyncFinishedDate] = useState(false);
  const [allowSyncOrderAmount, setAllowSyncOrderAmount] = useState(false);
  let flgConfirm = true;
  const [fileList, setFileList] = useState([]);
  const theme = window.localStorage.getItem("theme");
  const [isDarkMode, setIsDarkMode] = useState(!(!theme || theme === "light"));
  const [isDarkModeUpdateLock, setIsDarkModeUpdateLock] = useState(
    !(!theme || theme === "light")
  );
  const [ddlDepartmentnm, setDdlDepartmentnm] = useState(new classDdl());
  const [isUpdateLock, setIsUpdateLock] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // get param
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const onChangeSwitch = (checked) => {
    setIsDarkMode(checked);
  };
  const onChangeSwitchUpdateLock = (checked) => {
    setIsDarkModeUpdateLock(checked);
  };

  let oyaseino = params.get("oyaseino");
  let csncb1 =
    params.get("csncb1").length === 0 ? "    " : params.get("csncb1");

  let [showLoading, setShowLoading] = useState(false);
  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  //state auth
  const { allowRoute } = useSelector((state) => state.auth);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  //declare variable data grid
  const [dataDetail, setDataDetail] = useState({});

  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  const showDetail = async (data) => {
    setDataDetail(data);
    set__choosePopup(data.trhsakcd + "_" + data.tntkac);
    setTxtDepartmentName({ ...txtDepartmentName, value: data.hm17_BUNM });
    setTxtConstructionName({ ...txtConstructionName, value: data.kjiryknm });
    setTxtStartDate1({
      ...txtStartDate1,
      value: getDateValue(new Date(data.display_JYUYMD).getTime()),
    });
    settxtEndDate2({
      ...txtEndDate2,
      value: getDateValue(new Date(data.display_KYKNKIYMD).getTime()),
    });
    setTxthm20_TRHADRS2(data.hm20_TRHADRS2);
    setTxthm20_TRHADRS3(data.hm20_TRHADRS3);
    settxthm20_TRHADRS1(data.hm20_TRHADRS1);
    setTxthm20_TRHADRS2_OLD(data.hm20_TRHADRS2_old);
    setTxthm20_TRHADRS3_OLD(data.hm20_TRHADRS3_old);
    setTxthm20_TRHADRS1_OLD(data.hm20_TRHADRS1_old);

    let locationName = "";
    let ordererName = "";
    if (data.locationName) {
      locationName = `${data.locationName.trim()}（${data.trhsakcd}）`;
    }
    if (data.hm20_TRHSAKNM1) {
      ordererName = `${data.hm20_TRHSAKNM1.trim()}（${data.jyuskicd}）`;
    }
    setLocationName(locationName);
    settxthm20_TRHSAKNM1(ordererName);
    settxtNnyskicd(data.nnyskicd);
    settxtjyuskicd(data.jyuskicd);
    setTxtOfficeName(data.officeName);
    setxtRepresentativeName(data.representativeName);
    setOrderAmount(data.jyuznkkg);
    setAllowSyncDepartmentName(data.allowSyncDepartmentName);
    setAllowSyncConstructionName(data.allowSyncConstructionName);
    setAllowSyncLocationName(data.allowSyncLocationName);
    setAllowSyncContractDate(data.allowSyncContractDate);
    setAllowSyncOrdererName(data.allowSyncOrdererName);
    setAllowSyncFinishedDate(data.allowSyncFinishedDate);
    setAllowSyncOrderAmount(data.allowSyncOrderAmount);

    if (data.attached_document) {
      data.attached_document.split(",").map((item) =>
        setFileList((fileList) => [
          ...fileList,
          {
            uid: getAttachName(item.split("/")[item.split("/").length - 1], 0),
            name: getAttachName(item.split("/")[item.split("/").length - 1], 0),
            status: "done",
            url: item,
          },
        ])
      );
    }

    if (data.flgShowAttach === "0") {
      setIsDarkMode(false);
    } else {
      setIsDarkMode(true);
    }

    if (data.flgLock === "0") {
      setIsDarkModeUpdateLock(false);
    } else {
      setIsDarkModeUpdateLock(true);
    }
  };

  // fucntion get role of current user
  const funtGetRolesOfUser = () => {
    APIUser.getRolesOfUser().then((res) => {
      checkUserIsAdmin(res.data);
    });

    function checkUserIsAdmin(users) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].role === "0") {
          // role == 0 => update lock
          setIsUpdateLock(true);
          return;
        } else if (users[i].role === "1") {
          // role == 1 => admin
          setIsAdmin(true);
          return;
        }
      }
    }
  };

  function NewLineContent(props) {
    const text = props.text;
    const newText = text.split("\n");
    return (
      <div>
        <p>{newText[0]}</p>
        <p style={{ color: "red" }}>{newText[1]}</p>
      </div>
    );
  }

  const showMessageSaveContract = (rowData) => {
    const validateSave = () => {
      let flagErr = false,
        elmFocus;
      txtConstructionName = {
        ...txtConstructionName,
        value:
          typeof txtConstructionName.value === "string"
            ? txtConstructionName.value.trim()
            : "",
        error: false,
      };
      txtStartDate1 = {
        ...txtStartDate1,
        value: txtStartDate1.value,
        error: false,
      };

      if (txtStartDate1.value === null || txtStartDate1.value === "") {
        flagErr = true;
        // Border red
        txtStartDate1 = { ...txtStartDate1, error: true };
        elmFocus = txtStartDate1Ref;
      }

      if (txtConstructionName.value.length === 0) {
        flagErr = true;
        // Border red
        txtConstructionName = { ...txtConstructionName, error: true };
        elmFocus = txtConstructionNameEl;
      }

      // Focus item error
      elmFocus && elmFocus.current.focus();

      // Update state
      setTxtConstructionName(txtConstructionName);
      setTxtStartDate1(txtStartDate1);

      if (!flagErr) {
        funcDel();
      }
    };
    // Action update
    const funcDel = async () => {
      openLoadingSpinner();

      // API Update
      try {
        // Close warning
        Modal.destroyAll();

        // Create Form data
        const formData = new FormData();
        let txtattachFile;
        for (let i = 0; i < fileList.length; i++) {
          txtattachFile = txtattachFile
            ? txtattachFile +
            "," +
            getTypeAndFileName(fileList[i].name, 1) +
            "_" +
            oyaseino +
            "." +
            getTypeAndFileName(fileList[i].name, 2)
            : getTypeAndFileName(fileList[i].name, 1) +
            "_" +
            oyaseino +
            "." +
            getTypeAndFileName(fileList[i].name, 2);
        }

        let formInput = {
          oyaseino: oyaseino,
          csncb1: csncb1,
          kji1nm_kji2nm: txtConstructionName.value,
          nnyskicd: txtNnyskicd,
          jyuymd: int2DateString(Number(txtStartDate1.value)),
          jyuskicd: txtjyuskicd,
          kyknkiymd: int2DateString(Number(txtEndDate2.value)),
          attach_File: txtattachFile,
          flgConfirm: flgConfirm,
          flgShowAttach: isDarkMode,
          bunm: ddlDepartmentnm.value,
          flgLock: isDarkModeUpdateLock,
          allowSyncDepartmentName: allowSyncDepartmentName,
          allowSyncConstructionName: allowSyncConstructionName,
          allowSyncLocationName: allowSyncLocationName,
          allowSyncContractDate: allowSyncContractDate,
          allowSyncOrdererName: allowSyncOrdererName,
          allowSyncFinishedDate: allowSyncFinishedDate,
          allowSyncOrderAmount: allowSyncOrderAmount,
          jyuznkkg: txtOrderAmount,
          location_trhadrs1: !allowSyncLocationName ? txthm20_TRHADRS2 : txthm20_TRHADRS2_OLD,
          location_trhadrs2: !allowSyncLocationName ? txthm20_TRHADRS3 : txthm20_TRHADRS3_OLD,
          orderer_trhadrs: !allowSyncOrdererName ? txthm20_TRHADRS1 : txthm20_TRHADRS1_OLD
        };

        formData.append("data", JSON.stringify(formInput));
        // Open loading
        openLoadingSpinner();

        // Sent data to Server
        API.updateContractParent(formData)
          .then((res) => {
            if (statusRes(res)) {
              onFileUpload();
              // Success
              showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, backToSearch);
            } else {
              showMessage(KEY.ERROR, MSG_CATCH());
            }
          })
          .catch((err) => {
            let msg = "";
            if (
              err.response &&
              err.response.status === 400 &&
              err.response.data
            ) {
              msg = err.response.data.msg;
            } else {
              msg = MSG_CATCH();
            }
            showMessage(KEY.ERROR, msg);
          })
          .then(() => {
            closeLoadingSpinner();
          });
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: (
        <NewLineContent
          text={
            !flgConfirm
              ? COMMON_CONSTANT.MESSAGE_CONFIRM_SAVE_SUB_CONTRACT +
              "\n" +
              COMMON_CONSTANT.MESSAGE_CONFIRM_SAVE_CONTRACT_NOTE
              : COMMON_CONSTANT.MESSAGE_CONFIRM_SAVE_CONFIRM_SUB_CONTRACT
          }
        />
      ),
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: validateSave,
    });
  };

  //init
  useEffect(() => {
    funtGetRolesOfUser();
    init();
  }, [allowRoute]);

  const init = () => {
    // Open loading
    openLoadingSpinner();

    let dataDetail;
    let lstDepartmentName;
    Promise.all([
      oyaseino ? API.detail(oyaseino, csncb1) : null,
      API.getDepartmentName(),
    ])
      .then((res) => {
        dataDetail = res[0] ? res[0].data : [];
        lstDepartmentName = res[1] ? res[1].data : []; // ddlDepartment
      })
      .catch((err) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Data detail
        if (oyaseino) {
          showDetail(dataDetail);
          setDdlDepartmentnm({
            ...ddlDepartmentnm,
            options: lstDepartmentName,
            value: dataDetail.hm17_BUNM,
          });
        }
        closeLoadingSpinner();
      });
  };

  const changeComboxDepartment = async (bunm) => {
    let dataResult;
    Promise.all([API.getDataByChangeComboboxDepartment(oyaseino, csncb1, bunm)])
      .then((res) => {
        dataResult = res[0] ? res[0].data : [];
      })
      .catch((err) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Data detail
        setTxtOfficeName(dataResult.officeName);
        setxtRepresentativeName(dataResult.representativeName);
      });
  };

  // backToSearch
  function backToSearch() {
    history.goBack();
  }

  // POPUP
  const [popupChecklist, setPopupChecklist] = useState(new classPopup());
  const [_paramPopup, set__paramPopup] = useState(new classText());
  const [_choosePopup, set__choosePopup] = useState("");
  const [grid, setGrid] = useState(new classGrid());
  const [form] = Form.useForm();
  const [loadSpin, setLoadSpin] = useState(true);
  const [flgSearch, setFlgSearch] = useState(true);
  const [totalRecord, setTotalRecord] = useState();
  const [flgReset, setFlgReset] = useState(true);

  const getTrhsakcd = () => {
    if (
      (_paramPopup.trhsakcd && _paramPopup.trhsakcd.length > 0) ||
      _paramPopup.trhsakcd !== undefined
    ) {
      return _paramPopup.trhsakcd.trim();
    } else {
      return "";
    }
  };
  const getTrhsaknm1 = () => {
    if (
      (_paramPopup.trhsaknm1 && _paramPopup.trhsaknm1.length > 0) ||
      _paramPopup.trhsaknm1 !== undefined
    ) {
      return _paramPopup.trhsaknm1.trim();
    } else {
      return "";
    }
  };

  const onCheckboxChange = (evt) => {
    if (evt.target.value === "allowSyncDepartmentName") {
      setAllowSyncDepartmentName(!evt.target.checked);
    }
    if (evt.target.value === "allowSyncConstructionName") {
      setAllowSyncConstructionName(!evt.target.checked);
    }
    if (evt.target.value === "allowSyncLocationName") {
      setAllowSyncLocationName(!evt.target.checked);
    }
    if (evt.target.value === "allowSyncContractDate") {
      setAllowSyncContractDate(!evt.target.checked);
    }
    if (evt.target.value === "allowSyncOrdererName") {
      setAllowSyncOrdererName(!evt.target.checked);
    }
    if (evt.target.value === "allowSyncFinishedDate") {
      setAllowSyncFinishedDate(!evt.target.checked);
    }
    if (evt.target.value === "allowSyncOrderAmount") {
      setAllowSyncOrderAmount(!evt.target.checked);
    }
  };

  const popup = {
    show: () => {
      setPopupChecklist({ ...popupChecklist, show: true });
    },
    hide: () => {
      setPopupChecklist({ ...popupChecklist, show: false });
    },
    showPopup: () => {
      // Clear
      form.resetFields();
      // Re-search
      setFlgReset(true);
      popup.search(1);
      popup.show();
    },
    closePopup: () => {
      // Clear
      form.resetFields();
      set__paramPopup(new classText());
      set__choosePopup("");
      popup.hide();
    },
    search: async (page) => {
      // Loading
      setLoadSpin(true);
      return API.getDataPktphm20(getTrhsakcd(), getTrhsaknm1(), false, page)
        .then((res) => {
          const data = res.data;
          // push
          setGrid({ ...grid, data: data });
          if (data && data.length > 0) {
            setTotalRecord(data[0].rowCount);
          } else {
            setTotalRecord(0);
          }
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          setLoadSpin(false);
        });
    },
    searchPktphm20: async () => {
      const myArray = _choosePopup.split("_");
      return API.getDataPktphm20(myArray[0], myArray[1], true, 1)
        .then((res) => {
          const data = res.data;
          // push
          if (data && data.length > 0) {
            let locationName = `${data[0].trhsaknm1.trim()}（${data[0].trhsakcd}）`;
            if (flgSearch) {
              setTxthm20_TRHADRS2(data[0].hm20_TRHADRS2);
              setTxthm20_TRHADRS3(data[0].hm20_TRHADRS3);
              setTxthm20_TRHADRS2_OLD(data[0].hm20_TRHADRS2);
              setTxthm20_TRHADRS3_OLD(data[0].hm20_TRHADRS3);
              setLocationName(locationName);
              settxtNnyskicd(myArray[0]);
            } else {
              settxthm20_TRHSAKNM1(locationName);
              settxthm20_TRHADRS1(data[0].trhadrs);
              setTxthm20_TRHADRS1_OLD(data[0].trhadrs);
              settxtjyuskicd(myArray[0]);
            }
          }
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          popup.closePopup();
        });
    },
  };

  // UPLOAD
  const props = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    multiple: true,
    beforeUpload: false,
  };

  // On file upload (click the upload button)
  const onFileUpload = async () => {
    let token = await getToken();

    // Create an object of formData
    const formData = new FormData();
    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];
      const fileName = fileList[index].name;
      formData.append("file", element.originFileObj);
    }
    formData.append("oyaseino", dataDetail.oyaseino);
    // Request made to the backend api
    // Send formData object
    try {
      await axios({
        url: config.backendEndpoint + "ContractParent/ImportFile",
        method: "post",
        data: formData,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("ok");
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = ({ fileList }) => {
    // you store them in state, so that you can make a http req with them later
    setFileList(fileList);
  };

  return (
    <div className="des-content">
      <TitlePage name={lang.EDIT_CONTRACT_TITLE} />
      <GroupItem
        center
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 24 : "12px 24px",
        }}
      >
        <div
          className="title-page d-block left border-bottom-10px-orange mt10 pl-20 pr-20"
          style={{ marginBottom: "10px" }}
        >
          <h1 className="bold white-space">{lang.EDIT_BOTTOM_TITLE}</h1>
        </div>
        <BreakLine />
        <RowInput name={lang.OFFICE_NAME} box>
          {txtOfficeName}
        </RowInput>
        <BreakLine />
        <div className={"row-input-box"}>
          <Row>
            <Col span={6} className={"row-input-col-first"}>
              <Row justify="space-between">
                <Col span={2}>
                  <Checkbox
                    value={"allowSyncDepartmentName"}
                    onChange={onCheckboxChange}
                    checked={!allowSyncDepartmentName}
                  />
                </Col>
                <Col span={22} className="right pr20">
                  <label className="row-input-label ">{lang.DEPARTMENT}</label>
                </Col>
              </Row>
            </Col>
            <Col span={18} className="row-input-col-2">
              <Select
                allowClear
                showSearch
                placeholder={lang.DEPARTMENT_NAME}
                optionFilterProp="children"
                value={ddlDepartmentnm.value}
                className="w100"
                onChange={async (v) => {
                  setDdlDepartmentnm({
                    ...ddlDepartmentnm,
                    value: v === undefined ? null : v,
                  });
                  await changeComboxDepartment(v === undefined ? null : v);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
                }
              >
                {renderOptionCanInputSearch(ddlDepartmentnm.options)}
              </Select>
            </Col>
          </Row>
        </div>
        <BreakLine />
        <RowInput name={lang.REPRESENTATIVE_NAME} box>
          {txtRepresentativeName}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.MADE} box>
          {dataDetail.oyaseino}
        </RowInput>
        <BreakLine />

        <div className={"row-input-box"}>
          <Row>
            <Col span={6} className={"row-input-col-first"}>
              <Row justify="space-between">
                <Col span={2}>
                  <Checkbox
                    value={"allowSyncConstructionName"}
                    onChange={onCheckboxChange}
                    checked={!allowSyncConstructionName}
                  />
                </Col>
                <Col span={22} className="right pr20">
                  <label className="row-input-label ">
                    {lang.CONSTRUCTION_NAME}
                  </label>
                  <label className="blod ml5 mark-required">*</label>
                </Col>
              </Row>
            </Col>
            <Col span={18} className="row-input-col-2">
              <Input
                ref={txtConstructionNameEl}
                className={txtConstructionName.error ? STYLE.BORDER_RED : ""}
                value={txtConstructionName.value}
                onChange={(v) => {
                  setTxtConstructionName({
                    ...txtConstructionName,
                    value: v.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <BreakLine />

        <div className={"row-input-box"}>
          <Row>
            <Col span={6} className={"row-input-col-first"}>
              <Row justify="space-between">
                <Col span={2}>
                  <Checkbox
                    value={"allowSyncLocationName"}
                    onChange={onCheckboxChange}
                    checked={!allowSyncLocationName}
                  />
                </Col>
                <Col span={22} className="right pr20">
                  <label className="row-input-label ">
                    {lang.LOCATION_NAME}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col span={18} className="row-input-col-2">
              <Row justify="space-between">
                <Col>{locationName}</Col>
                <Col>
                  <Space size={8} align="center">
                    <Button
                      lg={8}
                      md={12}
                      xs={24}
                      htmlType="submit"
                      className="buttonPC button--info wAuto"
                      onClick={() => {
                        setFlgSearch(true);
                        popup.showPopup();
                      }}
                    >
                      <SearchOutlined />
                      {lang.SEARCH}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <BreakLine />
        {allowSyncLocationName ? (
          <RowInput name={lang.LOCATION_PROVINE} box>
            {txthm20_TRHADRS2_OLD}
          </RowInput>
        ) : (
          <div className={"row-input-box"}>
            <Row>
              <Col span={6} className={"row-input-col-first"}>
                <Row justify="space-between">
                  <Col span={2}>
                  </Col>
                  <Col span={22} className="right pr20">
                    <label className="row-input-label ">
                      {lang.LOCATION_PROVINE}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col span={18} className="row-input-col-2">
                <Input
                  value={txthm20_TRHADRS2}
                  onChange={(v) => {
                    setTxthm20_TRHADRS2(v.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        {/* <RowInput name={lang.LOCATION_PROVINE} box>
          {txthm20_TRHADRS2}
        </RowInput> */}
        <BreakLine />
        {allowSyncLocationName ? (
          <RowInput name={lang.LOCATION_DISTRICT} box>
            {txthm20_TRHADRS3_OLD}
          </RowInput>
        ) : (
          <div className={"row-input-box"}>
            <Row>
              <Col span={6} className={"row-input-col-first"}>
                <Row justify="space-between">
                  <Col span={2}>
                  </Col>
                  <Col span={22} className="right pr20">
                    <label className="row-input-label ">
                      {lang.LOCATION_DISTRICT}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col span={18} className="row-input-col-2">
                <Input
                  value={txthm20_TRHADRS3}
                  onChange={(v) => {
                    setTxthm20_TRHADRS3(v.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        {/* <RowInput name={lang.LOCATION_DISTRICT} box>
          {txthm20_TRHADRS3}
        </RowInput> */}
        <BreakLine />
        <div className={"row-input-box"}>
          <Row>
            <Col span={6} className={"row-input-col-first"}>
              <Row justify="space-between">
                <Col span={2}>
                  <Checkbox
                    value={"allowSyncContractDate"}
                    onChange={onCheckboxChange}
                    checked={!allowSyncContractDate}
                  />
                </Col>
                <Col span={22} className="right pr20">
                  <label className="row-input-label ">{lang.DATE_FROM}</label>
                  <label className="blod ml5 mark-required">*</label>
                </Col>
              </Row>
            </Col>
            <Col span={18} className="row-input-col-2">
              <DatePicker
                format={txtStartDate1.format || KEY.DATE_DEFAULT}
                ref={txtStartDate1Ref}
                value={txtStartDate1.value}
                picker={txtStartDate1.picker}
                placeholder={txtStartDate1.format || KEY.DATE_DEFAULT}
                className={
                  "w100 " + (txtStartDate1.error ? STYLE.BORDER_RED : "")
                }
                onChange={(v) => {
                  setTxtStartDate1({
                    ...txtStartDate1,
                    value: v,
                  });
                }}
                {...txtStartDate1.input}
              />
            </Col>
          </Row>
        </div>
        <BreakLine />

        <div className={"row-input-box"}>
          <Row>
            <Col span={6} className={"row-input-col-first"}>
              <Row justify="space-between">
                <Col span={2}>
                  <Checkbox
                    value={"allowSyncOrdererName"}
                    onChange={onCheckboxChange}
                    checked={!allowSyncOrdererName}
                  />
                </Col>
                <Col span={22} className="right pr20">
                  <label className="row-input-label ">
                    {lang.CUSTOMER_NAME}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col span={18} className="row-input-col-2">
              <Row justify="space-between">
                <Col>{txthm20_TRHSAKNM1}</Col>
                <Col>
                  <Space size={8} align="center">
                    <Button
                      lg={8}
                      md={12}
                      xs={24}
                      htmlType="submit"
                      className="buttonPC button--info wAuto"
                      onClick={() => {
                        setFlgSearch(false);
                        popup.showPopup();
                      }}
                    >
                      <SearchOutlined />
                      {lang.SEARCH}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <BreakLine />
        {allowSyncOrdererName ? (
          <RowInput name={lang.CUSTOMER_ADDRESS} box>
            {txthm20_TRHADRS1_OLD}
          </RowInput>
        ) : (
          <div className={"row-input-box"}>
            <Row>
              <Col span={6} className={"row-input-col-first"}>
                <Row justify="space-between">
                  <Col span={2}>
                  </Col>
                  <Col span={22} className="right pr20">
                    <label className="row-input-label ">
                      {lang.CUSTOMER_ADDRESS}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col span={18} className="row-input-col-2">
                <Input
                  value={txthm20_TRHADRS1}
                  onChange={(v) => {
                    settxthm20_TRHADRS1(v.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        <BreakLine />

        <div className={"row-input-box"}>
          <Row>
            <Col span={6} className={"row-input-col-first"}>
              <Row justify="space-between">
                <Col span={2}>
                  <Checkbox
                    value={"allowSyncFinishedDate"}
                    onChange={onCheckboxChange}
                    checked={!allowSyncFinishedDate}
                  />
                </Col>
                <Col span={22} className="right pr20">
                  <label className="row-input-label ">
                    {lang.DATE_FINSHED_CHECK}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col span={18} className="row-input-col-2">
              <DatePicker
                // size="large"
                format={txtEndDate2.format || KEY.DATE_DEFAULT}
                value={txtEndDate2.value}
                placeholder={txtEndDate2.format || KEY.DATE_DEFAULT}
                picker={txtEndDate2.picker}
                className="w100"
                onChange={(v) => {
                  settxtEndDate2({
                    ...txtEndDate2,
                    value: v,
                  });
                }}
                {...txtEndDate2.input}
              />
            </Col>
          </Row>
        </div>
        <BreakLine />
        <div className={"row-input-box"}>
          <Row>
            <Col span={6} className={"row-input-col-first"}>
              <Row justify="space-between">
                <Col span={2}>
                  <Checkbox
                    value={"allowSyncOrderAmount"}
                    onChange={onCheckboxChange}
                    checked={!allowSyncOrderAmount}
                  />
                </Col>
                <Col span={22} className="right pr20">
                  <label className="row-input-label ">
                    {lang.ORDER_AMOUNT}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col span={18} className="row-input-col-2">
              {txtOrderAmount != null &&
                `${formatNumber(txtOrderAmount)}（千円）（税抜）`}
            </Col>
          </Row>
        </div>
        <BreakLine />
        {isUpdateLock || isAdmin ? (
          <RowInput name={lang.UPDATE_LOCK} box>
            <Switch
              checked={isDarkModeUpdateLock}
              onChange={onChangeSwitchUpdateLock}
            />
          </RowInput>
        ) : null}
        {isUpdateLock || isAdmin ? <BreakLine /> : null}

        <RowInput name={lang.ATTACHED_DOCUMENT} box>
          <Row>
            <Col span={8}>
              <div>
                <Space>
                  <p style={{ fontWeight: "bold", padding: "10px" }}>
                    一般ユーザ表示:
                  </p>
                </Space>
                <Space align="right">
                  <Switch checked={isDarkMode} onChange={onChangeSwitch} />
                </Space>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={9}>
              <Space size={2} align="center">
                <Upload
                  {...props}
                  style={{ color: "black" }}
                  multiple={true}
                  fileList={fileList}
                  onChange={handleUpload}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>添付ファイル</Button>
                </Upload>
              </Space>
            </Col>
          </Row>
        </RowInput>
        <BreakLine />
      </GroupItem>
      <FooterPage>
        <Row
          justify="space-between"
          style={{ float: "right", marginRight: "20px" }}
        >
          <Col span={24}>
            <Space size={8} align="center">
              {dataDetail.flaG_DEL === null ? (
                <Button
                  lg={8}
                  md={12}
                  xs={24}
                  htmlType="submit"
                  className="buttonPC button--info wAuto"
                  onClick={() => {
                    flgConfirm = true;
                    showMessageSaveContract();
                  }}
                >
                  <SaveOutlined />
                  保存確定
                </Button>
              ) : (
                []
              )}
              <Button
                lg={8}
                md={12}
                xs={24}
                htmlType="submit"
                className="buttonPC button--info wAuto"
                onClick={() => {
                  flgConfirm = false;
                  showMessageSaveContract();
                }}
              >
                <SaveOutlined />
                保存
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml20"
                onClick={() => history.goBack()}
              >
                <RollbackOutlined />
                {" " + COMMON_CONSTANT.BACK}
              </Button>
            </Space>
          </Col>
        </Row>
      </FooterPage>

      <Modal
        className={"popupP2"}
        centered
        title={"取引先マスター"}
        visible={popupChecklist.show}
        onCancel={popup.closePopup}
        width={1000}
        align="left"
        footer={[
          <Row
            justify="space-between"
            style={{ float: "right", marginRight: "20px" }}
          >
            <Col span={24}>
              <Space size={8} align="center">
                <Button
                  onClick={() => {
                    popup.searchPktphm20();
                  }}
                  htmlType="submit"
                  className="buttonPC button--info wAuto"
                >
                  {lang.OK}
                </Button>
                ,
                <Button key="back" onClick={popup.closePopup}>
                  <CloseCircleOutlined />
                  {lang.CANCEL}
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <PopupCusLocaName
          isPC={isPC}
          popup={popup}
          grid={grid}
          _paramPopup={_paramPopup}
          set__paramPopup={set__paramPopup}
          loadSpin={loadSpin}
          _choosePopup={_choosePopup}
          set__choosePopup={set__choosePopup}
          totalRecord={totalRecord}
          flgReset={flgReset}
          setFlgReset={setFlgReset}
        />
      </Modal>
      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default EditContractParent;
