/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Spin, Table, Radio } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import "./stylePopup.css";
import { lang } from "../../constants/common.const";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

function PopupCusLocaName({
  isPC,
  popup,
  grid,
  _paramPopup,
  set__paramPopup,
  loadSpin,
  _choosePopup,
  set__choosePopup,
  totalRecord,
  flgReset,
  setFlgReset,
}) {
  // Paging
  const [pagingImplement, setPagingImplement] = useState({
    position: ["bottomCenter"],
    hideOnSinglePage: true,
  });
  const onChange = (pagination) => {
    popup.search(pagination.current);
    setPagingImplement({ ...pagingImplement, current: pagination.current });
  };

  //state auth
  const { allowRoute } = useSelector((state) => state.auth);
  useEffect(() => {
    // Event only mobile
    !isPC && popup.search(1);
  }, [allowRoute]);

  useEffect(() => {
    if (totalRecord > 5) {
      setPagingImplement({
        ...pagingImplement,
        pageSize: 5,
        total: totalRecord,
      });
    } else {
      setPagingImplement({
        ...pagingImplement,
        pageSize: totalRecord,
        total: totalRecord,
      });
    }
  }, [totalRecord]);
  useEffect(() => {
    setPagingImplement({ ...pagingImplement, current: 1 });
    setFlgReset(false);
  }, [flgReset]);

  const initColumns = [
    {
      align: "left",
      dataIndex: "checked",
      fixed: "left",
      width: "0.65%",
      render: (text, row) => (
        <Radio.Group
          onChange={(e) => {
            set__choosePopup(e.target.value);
          }}
          value={_choosePopup}
          style={{ width: "100%" }}
        >
          <Row justify="space-between">
            <Radio value={row.trhsakcd + "_" + row.tntkac}></Radio>
          </Row>
        </Radio.Group>
      ),
    },
    {
      title: "取引先コード",
      dataIndex: "trhsakcd",
      align: "left",
      width: "3%",
    },
    // {
    //   title: "担当課",
    //   dataIndex: "tntkac",
    //   align: "center",
    //   width: "2%",
    // },
    {
      title: "会社名",
      dataIndex: "trhsaknm1",
      align: "left",
      width: "7%",
    },
    {
      title: "住所",
      dataIndex: "trhadrs",
      align: "left",
      width: "10%",
    },
  ];
  return (
    <div>
      <div>
        <Row className={isPC && "mb15 pl5 pr5"}>
          <Col span={24}>
            <Row gutter={[32, 8]}>
              <Col>
                <Input
                  width="30%"
                  placeholder={"取引先コード"}
                  value={_paramPopup.trhsakcd}
                  maxLength={6}
                  onChange={async (e) => {
                    set__paramPopup({
                      ..._paramPopup,
                      trhsakcd: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </Col>
              <Col>
                <Input
                  width="30%"
                  placeholder={"会社名"}
                  value={_paramPopup.trhsaknm1}
                  onChange={async (e) => {
                    set__paramPopup({
                      ..._paramPopup,
                      trhsaknm1: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    popup.search(1);
                    setPagingImplement({ ...pagingImplement, current: 1 });
                  }}
                  htmlType="submit"
                  className="buttonPC button--info wAuto"
                >
                  <SearchOutlined />
                  {lang.SEARCH}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Grid */}
        <div InfiniteScroll>
          <Spin spinning={loadSpin} className="content-center"></Spin>
          <InfiniteScroll initialLoad={false} pageStart={0} useWindow={false}>
            {isPC ? (
              <Table
                columns={initColumns}
                dataSource={grid.data}
                pagination={pagingImplement}
                onChange={onChange}
                scroll={{ x: 1100 }}
              />
            ) : (
              []
            )}
          </InfiniteScroll>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default PopupCusLocaName;
