import React, { useEffect, useRef, useState } from "react";
import TitlePage from "../../component/Desktop/titlePage";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../constants/common.const";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import {
  MSG_CATCH,
  renderOption,
  showMessage,
  statusRes,
} from "../../constants/utils";
import { Button, DatePicker, Input, Modal, Row, Select } from "antd";
import Loading from "../../component/Desktop/loading/loading";
import GroupItem from "../../component/Desktop/groupItem";
import BreakLine from "../../component/Desktop/breakLine";
import RowInput from "../../component/Desktop/rowInput";
import classText from "../../models/control/text";
import classDdl from "../../models/control/dropdownlist";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";
import API from "../../api/backend/representativeMaster";
import moment from "moment";

//Declare global variables
let FLAG_CHANGE_DATA = false;

function CreateRepresentativeMaster() {
  let [showLoading, setShowLoading] = useState(false);
  const history = useHistory();
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  let { id } = useParams();
  let [txtRepresentativeName, settxtRepresentativeName] = useState(
    new classText()
  ); //Required
  let [inaugurationDate, setinaugurationDate] = useState(new classText()); //Required
  let [ddlOffice, setddlOffice] = useState(new classDdl()); //Required

  let txtRepresentativeNameEl = useRef(null);
  let inaugurationDateRef = useRef(null);
  let ddlOfficeEl = useRef(null);

  let [initPage, setInitPage] = useState(true);

  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  function confirmSaveData() {
    if (validateSave()) {
      let message_confirm = id
        ? COMMON_CONSTANT.CONFIRM_UPDATE_REPRESENTATIVE
        : COMMON_CONSTANT.CONFIRM_REGISTER_REPRESENTATIVE;
      showConfirmMessage(message_confirm, function () {
        openLoadingSpinner();
        // Create Form data
        const formData = new FormData();

        let formInput = {
          representativeName: txtRepresentativeName.value,
          officeId: ddlOffice.value,
          inaugurationDate: inaugurationDate.value,
        };

        formData.append("data", JSON.stringify(formInput));
        // Sent data to Server
        API.save(formData, id)
          .then((res) => {
            if (statusRes(res)) {
              // Success
              showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, backToSearch);
            } else {
              showMessage(KEY.ERROR, MSG_CATCH());
            }
          })
          .catch((err) => {
            let msg = "";
            if (
              err.response &&
              err.response.status === 400 &&
              err.response.data
            ) {
              msg = err.response.data.msg;
            } else {
              msg = MSG_CATCH();
            }
            showMessage(KEY.ERROR, msg);
          })
          .then(() => {
            closeLoadingSpinner();
          });
      });
    }
  }
  function validateSave() {
    let flagErr = false,
      elmFocus;
    txtRepresentativeName = {
      ...txtRepresentativeName,
      value:
        typeof txtRepresentativeName.value === "string"
          ? txtRepresentativeName.value.trim()
          : "",
      error: false,
    };
    inaugurationDate = {
      ...inaugurationDate,
      value: inaugurationDate.value,
      error: false,
    };
    ddlOffice = { ...ddlOffice, error: false };

    if (inaugurationDate.value === null || inaugurationDate.value === "") {
      flagErr = true;
      // Border red
      inaugurationDate = { ...inaugurationDate, error: true };
      elmFocus = inaugurationDateRef;
    }

    if (!ddlOffice.value || ddlOffice.value === "") {
      flagErr = true;
      // Border red
      ddlOffice = { ...ddlOffice, error: true };
      elmFocus = ddlOfficeEl;
    }

    if (txtRepresentativeName.value.length === 0) {
      flagErr = true;
      // Border red
      txtRepresentativeName = { ...txtRepresentativeName, error: true };
      elmFocus = txtRepresentativeNameEl;
    }

    // Focus item error
    elmFocus && elmFocus.current.focus();

    // Update state
    settxtRepresentativeName(txtRepresentativeName);
    setinaugurationDate(inaugurationDate);
    setddlOffice(ddlOffice);

    return !flagErr;
  }

  // backToSearch
  function backToSearch() {
    history.goBack();
  }

  // Click button Cancel
  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      // No update
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(
        COMMON_CONSTANT.BOM_C018,
        function () {
          backToSearch();
        },
        function () {
          FLAG_CHANGE_DATA = true;
        }
      );
    }
  }

  const showDetail = async (data) => {
    settxtRepresentativeName({
      ...txtRepresentativeName,
      value: data.representativeName,
    });
  };

  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
    }
  }, [txtRepresentativeName, inaugurationDate, ddlOffice]);

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      openLoadingSpinner();
      setInitPage(true);

      // Detail
      Promise.all([id ? API.getDetail(id) : null, API.getOfficeName()])
        .then((res) => {
          let d1 = res[0] ? res[0].data : {};
          let typeOption = res[1] ? res[1].data : []; // ddlOffice

          let inaugurationDate = d1.inaugurationDateDis
            ? moment.unix(d1.inaugurationDateDis)
            : moment(new Date());
          setinaugurationDate({ ...inaugurationDate, value: inaugurationDate });
          // Data detail
          if (id) {
            setddlOffice({
              ...ddlOffice,
              options: typeOption,
              value: d1.officeId,
            });
            // Detail - 0
            showDetail(d1);
          } else {
            setddlOffice({ ...ddlOffice, options: typeOption });
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          setTimeout(() => {
            setInitPage(false);
          }, 500); // waiting for setup
          closeLoadingSpinner();
        });
    }
  }, [allowRoute]);

  // Popup common
  const showConfirmMessage = (
    msg,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    let ok, cancel;
    let callBackDefault = () => {
      Modal.destroyAll();
    };

    // Callback OK exist
    if (callbackOK) {
      ok = () => {
        callbackOK();
        callBackDefault();
      };
    }
    // Callback Cancel exist
    if (callbackCancel) {
      cancel = () => {
        callbackCancel();
        callBackDefault();
      };
    }

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: msg,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: cancel || callBackDefault,
      onOk: ok || callBackDefault,
    });
  };

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage
        name={id ? lang.REPRESENTATIVE_UPDATE : lang.REPRESENTATIVE_CREATE}
      />
      <GroupItem center>
        <div className="mt15"></div>
        <BreakLine />
        <RowInput name={lang.REPRESENTATIVE_TXT} required box>
          <Input
            ref={txtRepresentativeNameEl}
            className={txtRepresentativeName.error ? STYLE.BORDER_RED : ""}
            value={txtRepresentativeName.value}
            maxLength={10}
            onChange={(v) => {
              settxtRepresentativeName({
                ...txtRepresentativeName,
                value: v.target.value,
              });
            }}
          />
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REPRESENTATIVE_OFF_NAME} required box>
          <Select
            ref={ddlOfficeEl}
            value={ddlOffice.value}
            className={ddlOffice.error ? `${STYLE.BORDER_RED} w100` : "w100"}
            onChange={(v) => {
              setddlOffice({ ...ddlOffice, value: v });
            }}
          >
            {renderOption(ddlOffice.options)}
          </Select>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REPRESENTATIVE_DATE} required box>
          <DatePicker
            format={inaugurationDate.format || KEY.DATE_DEFAULT}
            ref={inaugurationDateRef}
            value={inaugurationDate.value}
            picker={inaugurationDate.picker}
            placeholder={inaugurationDate.format || KEY.DATE_DEFAULT}
            disabledDate={(d) => !d || d.isAfter(new Date())}
            className={
              "w100 " + (inaugurationDate.error ? STYLE.BORDER_RED : "")
            }
            onChange={(v, dateString) => {
              setinaugurationDate({
                ...inaugurationDate,
                value: v,
              });
            }}
            {...inaugurationDate.input}
          />
        </RowInput>
        <BreakLine />
        {/* Button footer */}
        <Row justify="end" className="mt10 mb10">
          <Button
            className="buttonPC button--info wAuto ml15"
            onClick={() => {
              confirmSaveData();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={cancelSaveData}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>
        </Row>
      </GroupItem>
    </div>
  );
}
export default CreateRepresentativeMaster;
