// Import libraries
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { KEY, lang, COMMON_CONSTANT } from "../../constants/common.const";
import { CloseCircleOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { selectRowSearch } from "@src/redux/checkList";
import classGrid from "../../models/control/grid";
import PopupContractNumber from "../../component/Desktop/popupContractNumber";
import classText from "../../models/control/text";
import {
    Col,
    Row,
    Space,
    Button,
    Input,
    Radio,
    Table,
    Pagination,
    Checkbox,
    Modal
} from "antd";
import API from "../../api/backend/contractSub";
import TitlePage from "../../component/Desktop/titlePage";
import { Content } from "antd/lib/layout/layout";
import Loading from "../../component/Desktop/loading/loading";
import {
    MSG_CATCH,
    showMessage,
    statusRes
} from "../../constants/utils";
import FooterPage from "../../component/footer/footerPage";

function ViewBusinessPartnerMaster({ history: historyPage }) {
    const dispatch = useDispatch();
    const [tradingpartnermaster, setTradingpartnermaster] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [selectRow, setSelectRow] = useState();
    const [grid, setGrid] = useState(new classGrid());
    const [isModalOpenContractNumber, setIsModalOpenContractNumber] = useState(false);
    const [newlistContractNumber, setNewlistContractNumber] = useState(null);
    const [isTxtSubcontractor, setTxtSubcontractor] = useState(new classText()); //Required
    const [trhsakcdold, setTrhsakcdold] = useState(null);
    const [tntkacold, setTtntkacold] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [isLoadding, setIsLoadding] = useState(true);
    const [current, setCurrent] = useState(1);
    const [checkAll, setCheckAll] = useState(false);
    const [isButtonDisplay, setIsButtonDisplay] = useState(false);
    const [isButtonHidden, setIsButtonHidden] = useState(false);

    const getRowIndex = () => {
        if (grid.data && grid.data.length > 0) {
            // const arrayRow = grid.data.filter((_item, index) => { index == parseInt(selectRow) });
            const arrayRow = grid.data.filter((word, index, arr) => {
                return index == parseInt(selectRow);
            });
            dispatch(selectRowSearch(arrayRow));
        }
    }

    const onSearch = () => {
        setCurrent(1);
        getContractSubName(1);
    }

    //init
    useEffect(() => {
        getContractSubName(1);
    }, []);

    //change page
    useEffect(() => {
        if (current) {
            getContractSubName(current);
        }
    }, [current]);

    useEffect(() => {
        if (grid.data) {
            if (grid.data.length > 0) {
                setIsLoadding(false);
                const countCheckDisplay = grid.data.filter((item) => item.checked == true && item.delflg == '表示').length;
                if (countCheckDisplay > 0) {
                    setIsButtonDisplay(false)
                } else {
                    setIsButtonDisplay(true)
                }

                const countCheckHidden = grid.data.filter((item) => item.checked == true && item.delflg == '非表示').length;
                if (countCheckHidden > 0) {
                    setIsButtonHidden(false)
                } else {
                    setIsButtonHidden(true)
                }

                const countCheck = grid.data.filter((item) => item.checked).length;
                if (
                    grid.data.length === countCheck
                ) {
                    setCheckAll(true);
                } else {
                    setCheckAll(false);
                }
            }
        }
    }, [grid.data]);

    useEffect(() => {
        const newGrid = JSON.parse(JSON.stringify(grid));
        if (checkAll) {
            newGrid.data = newGrid.data.map((item) => {
                item.checked = true;
                return item;
            });
            setGrid(newGrid);
        } else {
            if (
                grid.data.length === grid.data.filter((item) => item.checked).length
            ) {
                newGrid.data = newGrid.data.map((item) => {
                    item.checked = false;
                    return item;
                });
                setGrid(newGrid);
            }
        }
    }, [checkAll]);

    const getContractSubName = (pcurrent) => {
        setIsLoadding(true);
        const objSearch = { pCompanyName: companyName, pTradingpartnermaster: tradingpartnermaster, currentPage: pcurrent, pageSize: pageSize, type: 0 };
        return API.contractSubName({
            params: {
                ...objSearch,
            },
        })
            .then((res) => {

                // Update key
                if (res.data && res.data.length > 0) {
                    const pageCount = res.data[0].totalCount;
                    let grid = res.data;
                    grid = grid.map((v, i) => {
                        v["rowKey"] = i;
                        return v;
                    });

                    // Set data to grid
                    setGrid({ ...grid, data: grid, count: pageCount });
                } else {
                    // Set data to grid
                    setGrid({ ...grid, data: [], count: 0 });
                }
                setIsLoadding(false);
                setSelectRow('');
            })
            .catch((err) => {
                setIsLoadding(false);
                showMessage(KEY.ERROR, MSG_CATCH());
            })
            .then(() => {
                setIsLoadding(false);
            });
    };

    const updateStatusBusinessPartnerMaster = async (pType) => {
        showConfirmMessage(pType, async function () {
            // Create Form data
            setIsLoadding(true);
            const listModel = [];
            grid.data.map((item) => {
                if (item.checked) {
                    listModel.push({ trhsakcd: item.trhsakcd, tntkac: item.tntkac, type: pType });
                }
            });
            await API.UpdateBusinessPartnerMaster(listModel)
                .then((res) => {
                    if (statusRes(res)) {
                        // Success
                        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
                        getContractSubName(1);
                        setCurrent(1);
                    } else {
                        showMessage(KEY.ERROR, MSG_CATCH());
                    }
                })
                .catch((err) => {
                    let msg = "";
                    if (
                        err.response &&
                        err.response.status === 400 &&
                        err.response.data
                    ) {
                        msg = err.response.data.msg;
                    } else {
                        msg = MSG_CATCH();
                    }
                    showMessage(KEY.ERROR, msg);
                })
                .then(() => {
                    setIsLoadding(false);
                });
        });
    };

    // Popup common
    const showConfirmMessage = (
        type,
        callbackOK,
        callbackCancel
    ) => {
        let ok, cancel;
        let callBackDefault = () => {
            Modal.destroyAll();
        };

        // Callback OK exist
        if (callbackOK) {
            ok = () => {
                callbackOK();
                callBackDefault();
            };
        }
        // Callback Cancel exist
        if (callbackCancel) {
            cancel = () => {
                callbackCancel();
                callBackDefault();
            };
        }

        Modal.confirm({
            centered: true,
            title: lang.CONFIRM,
            content: (
                <NewLineContent
                    type={type}
                />
            ),
            okText: lang.OK,
            cancelText: lang.CANCEL,
            onCancel: cancel || callBackDefault,
            onOk: ok || callBackDefault,
        });
    };

    function NewLineContent(props) {
        const ptype = props.type;
        return (
            <>
                {ptype == 0 ? (
                    <div>
                        <p>{lang.MSDISPLAY1}<span style={{ color: "red" }}>{lang.MSDISPLAY5}</span>{lang.MSDISPLAY2}</p>
                        <p>{lang.MSDISPLAY3}<span style={{ color: "red" }}>{lang.MSDISPLAY6}</span>{lang.MSDISPLAY4}</p>
                    </div>
                ) : (
                    <div>
                        <p>{lang.MSHIDDEN1}<span style={{ color: "red" }}>{lang.MSDISPLAY5}</span>{lang.MSHIDDEN2}</p>
                        <p>{lang.MSDISPLAY3}<span style={{ color: "red" }}>{lang.MSDISPLAY6}</span>{lang.MSDISPLAY4}</p>
                    </div>
                )}
            </>
        );
    }

    const showModalContractNumber = async (pRow) => {
        setShowLoading(true);
        const objSearch = { id: null, trhsakcd: pRow.trhsakcd, tntkac: pRow.tntkac, oyaseino: '' };
        await API.getContractNumber({
            params: {
                ...objSearch,
            },
        })
            .then((res) => {
                if (res.data != null) {
                    // let newArr2 = [...newlistContractNumber];
                    // newArr2 = res.data;
                    setNewlistContractNumber(res.data);
                }
                setIsModalOpenContractNumber(true);
                setTrhsakcdold(pRow.trhsakcd);
                setTtntkacold(pRow.tntkac);
                setShowLoading(false);
            })
            .catch((err) => {
                setShowLoading(false);
                showMessage(KEY.ERROR, MSG_CATCH());
            });
    };

    //checked
    const hancleChangeChecked = (rowKey) => (e) => {
        const newGrid = JSON.parse(JSON.stringify(grid));
        const index = newGrid.data.findIndex((item) => item.rowKey === rowKey);
        if (index >= 0) {
            newGrid.data[index].checked = e.target.checked;
            setGrid(newGrid);
        }
    };

    // Column GRID
    const initColumns = [
        {
            title: (
                <Checkbox
                    disabled={!grid.data.length}
                    checked={checkAll}
                    onChange={(e) => setCheckAll(e.target.checked)}
                ></Checkbox>
            ),
            align: "center",
            dataIndex: "checked",
            fixed: "left",
            render: (text, row) => (
                <Checkbox
                    checked={row.checked}
                    onChange={hancleChangeChecked(row.rowKey)}
                />
            ),
            width: "2%",
        },
        {
            title: "取引先コード",
            dataIndex: "trhsakcd",
            width: "10%",
            align: "center",
        },
        // {
        //     title: "担当課",
        //     dataIndex: "tntkac",
        //     width: "10%",
        //     align: "center",
        // },
        {
            title: "会社名",
            dataIndex: "trhsaknM1",
            width: "35%",
            align: "left",
        },
        {
            title: "住所",
            dataIndex: "trhadrs",
            width: "35%",
            align: "left",
        },
        {
            title: "表示／非表示",
            dataIndex: "delflg",
            width: "8%",
            align: "center",
        },
        {
            title: "",
            align: "center",
            dataIndex: "button",
            render: (text, row) => (
                <Button
                    className="buttonPC button--info wAuto ml15"
                    onClick={(v) => { showModalContractNumber(row) }}
                >
                    <PlusOutlined />
                    {" " + lang.ADD_CONTRACTOR_NUMBER}
                </Button>
            ),
            width: "100px",
        }
    ];

    const pageSize = 15;

    // Custom pagination component
    const MyPagination = ({ total, onChange, current }) => {
        return (
            <Pagination
                className="center"
                onChange={onChange}
                total={total}
                current={current}
                pageSize={pageSize}
            />
        );
    };

    return (
        <div className="des-content">
            <TitlePage name={lang.TRADINGPARTNERMASTER} />
            <Content
                className="site-layout-background"
                style={{
                    margin: "24px 16px",
                    padding: 24,
                }}
            >
                <div className="horizontal-Item pt15">
                    <div>
                        <div>{lang.TRADINGPARTNERMASTER}</div>
                        <div className="mr15">
                            <Input
                                value={tradingpartnermaster.value}
                                maxLength={70}
                                placeholder={lang.TRADINGPARTNERMASTER}
                                onChange={(v) => {
                                    setTradingpartnermaster(v.target.value);
                                }} />
                        </div>
                    </div>
                    <div>
                        <div>{lang.COMPANYNAME}</div>
                        <div className="mr15">
                            <Input
                                value={companyName.value}
                                maxLength={70}
                                placeholder={lang.COMPANYNAME}
                                onChange={(v) => {
                                    setCompanyName(v.target.value);
                                }} />
                        </div>
                    </div>
                    <Space size={20} align="center">
                        <Button
                            className="buttonPC button--info wAuto mt20"
                            disabled={isLoadding}
                            onClick={onSearch}
                        >
                            <SearchOutlined />
                            {" " + lang.SEARCH}
                        </Button>
                    </Space>
                </div>
                <br />
                {grid.data ? (
                    <>
                        <Table
                            rowKey={(record) => record.id}
                            columns={initColumns}
                            dataSource={grid.data}
                            loading={isLoadding}
                            pagination={false}
                        />
                        <br />
                        <MyPagination
                            total={grid.count}
                            current={current}
                            onChange={setCurrent}
                        />
                    </>
                ) : (
                    <></>
                )}
            </Content>
            <FooterPage>
                <Row
                    justify="space-between "
                    style={{ float: "right", marginRight: "20px" }}
                >
                    <col span={10}></col>
                    <Col>
                        <Button
                            lg={8}
                            md={12}
                            xs={24}
                            style={{
                                right: "10px",
                            }}
                            disabled={isButtonHidden}
                            className="buttonPC button--info wAuto"
                            onClick={(v) => { updateStatusBusinessPartnerMaster(0) }}
                        >
                            {lang.BUTTONSHOWMASTER}
                        </Button>
                        <Button
                            lg={8}
                            md={12}
                            xs={24}
                            style={{
                                right: "10px",
                            }}
                            disabled={isButtonDisplay}
                            className="buttonPC button--info wAuto ml10"
                            onClick={(v) => { updateStatusBusinessPartnerMaster(1) }}
                        >
                            {lang.BUTTONHIDENMASTER}
                        </Button>
                    </Col>
                </Row>
            </FooterPage>
            <PopupContractNumber show={isModalOpenContractNumber}
                sbubcontractorName={isTxtSubcontractor.value}
                setIsModalOpenContractNumber={setIsModalOpenContractNumber}
                listContractNumber={newlistContractNumber}
                setNewlistContractNumber={setNewlistContractNumber}
                trhsakcdold={trhsakcdold}
                tntkacold={tntkacold}
                oyaseino=""
                thino="" />
            <Loading show={showLoading}></Loading>
        </div>
    );
}
export default ViewBusinessPartnerMaster;