import React from "react";
import { Col, Row } from "antd";

function RowInput({ box, text, required, labelCol = 6, ...props }) {
  return (
    <div className={box ? "row-input-box" : "row-input"}>
      <Row justify="space-between">
        <Col span={24} className="left pr20">
          <label className="row-input-label ">{props.name}</label>
          {required && <label className="blod ml5 mark-required">*</label>}
        </Col>
      </Row>
    </div>
  );
}

export default RowInput;
