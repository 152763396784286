import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Table, Space, Modal, Button, Tooltip, Checkbox } from "antd";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  CONFIG,
} from "../../constants/common.const";
import { useHistory } from "react-router-dom";
import Loading from "../../component/Desktop/loading/loading";
import API from "../../api/backend/contractSub";
import APIUser from "../../api/backend/auth";
import APIContract from "../../api/backend/contractParent";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import TitlePage from "../../component/Desktop/titlePage";
import classGrid from "../../models/control/grid";

import FooterPage from "../../component/footer/footerPage";
import { RollbackOutlined } from "@ant-design/icons";
import BoxContent from "../../component/Desktop/content";
import { useMediaQuery } from "react-responsive";
import { formatNumber } from "../../utils/helper";

function ListRemoveSubcontract({ history: historyPage }) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const { allowRoute } = useSelector((state) => state.auth);
  const history = useHistory();

  //  Grid
  const [grid, setGrid] = useState(new classGrid());

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  const showMessageInfo = (msg, func) => {
    showMessage(KEY.INFO, msg, func);
  };

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // get param
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let oyaseino = params.get("oyaseino");
  let csncb1 = params.get("csncb1") === "" ? "    " : params.get("csncb1");

  //variable check isAdmin
  const [isAdmin, setIsAdmin] = useState(false);

  const revertButtonDeleteAll =
    grid.data.filter((item) => item.checked).length < 1;

  const [checkAll, setCheckAll] = useState(false);

  // Init
  useEffect(() => {
    funtGetRolesOfUser();
    getListData();
  }, [allowRoute]);

  const getListData = async () => {
    // Loading
    openLoadingSpinner();

    // check status contract
    let statusContract = funcGetStatusContract(oyaseino, csncb1);
    if (statusContract == 2) {
      showMessageInfo("This contract was accepted", history.goBack());
      return;
    } else if (statusContract == 1) {
      showMessageInfo("This contract was deleted", history.goBack());
      return;
    }
    return API.listRemove(oyaseino, csncb1)
      .then((res) => {
        setGrid({ ...grid, data: res.data, count: res.data.length });
      })
      .catch(() => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };

  // fucntion get role of current user

  const funtGetRolesOfUser = () => {
    APIUser.getRolesOfUser().then((res) => {
      checkUserIsAdmin(res.data);
    });

    function checkUserIsAdmin(users) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].role === "1") {
          // role == 1 => admin
          setIsAdmin(true);
          return;
        }
      }
    }
  };

  // function onclick button BACK : "復旧"
  const showMessageRevertConfirm = (rowData) => {
    // Action revert
    const funcRevert = async () => {
      openLoadingSpinner();
      try {
        // Close warning
        Modal.destroyAll();

        // API revert subcontract data
        await API.revert(rowData.thino).then((res) => {
          if (res.status == 409) {
            //Error
            showMessage(KEY.ERROR, MSG_CATCH());
          } else if ((res.status == 401)) {
            // Unauthorized
            showMessageInfo(COMMON_CONSTANT.COMMON_CONSTANT, () => {});
          }
        });

        //reload data page
        getListData();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C039);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.CONFIRM_REVERT_SUBCONTRACT,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcRevert,
    });
  };

  // check status contract
  const funcGetStatusContract = async (oyaseino, csncb1) => {
    await APIContract.getStatusContract(oyaseino, csncb1)
      .then((res) => {
        return res.data.result;
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };

  const showMessageRevertContractSubChecked = (list) => {
    // action delete all
    const funcRevertAllChecked = async () => {
      openLoadingSpinner();
      try {
        // Close warning
        Modal.destroyAll();

        let listSubContractChecked = [];
        list.forEach((element) => {
          if (element.checked) {
            listSubContractChecked.push(element.thino);
          }
        });

        //API revert all subcontract seleted
        await API.revertChecked(listSubContractChecked).then((res) => {
          if (res.status === 409) {
            //Error
            showMessage(KEY.ERROR, MSG_CATCH());
          } else if (res.status === 401) {
            // Unauthorized
            showMessageInfo(COMMON_CONSTANT.COMMON_CONSTANT, () => {});
          }
          console.log(res);
        });

        //reload page
        funtGetRolesOfUser();
        getListData();

        //clear listSubContractChecked
        listSubContractChecked = [];

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C039);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    //modal
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.COMFIRM_REVERT_ALL,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcRevertAllChecked,
    });
  };

  // func handle click checkbox
  const handleChangeChecked = (thino) => (e) => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    const index = newGrid.data.findIndex((item) => item.thino === thino);
    if (index >= 0) {
      newGrid.data[index].checked = e.target.checked;
      setGrid(newGrid);
    }
  };

  // func handel click checkbox "all"
  const handleChangeCheckedAll = () => (e) => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    setCheckAll(e.target.checked);
    if (checkAll != true) {
      newGrid.data.forEach((element) => {
        element.checked = true;
      });
    } else {
      newGrid.data.forEach((element) => {
        element.checked = false;
      });
    }
    setGrid(newGrid);
  };

  //define column table
  const initColumns = [
    {
      title: (
        <Checkbox
          disabled={grid.data.length <= 0}
          checked={checkAll}
          onChange={handleChangeCheckedAll()}
        ></Checkbox>
      ),
      align: "center",
      dataIndex: "checked",
      fixed: "left",
      render: (text, row) => (
        <Checkbox
          checked={row.checked}
          onChange={handleChangeChecked(row.thino)}
        />
      ),
      width: "50px",
    },
    {
      title: lang.SUBCONTRACTOR,
      dataIndex: "subcontractor",
      align: "left",
      width: "200px",
      fixed: "left",
      render: (text, row) => {
        return (
          <Tooltip trigger={["hover"]} title={row.hm20_TRHSAKNM1}>
            <span
              className="underline"
              onClick={() => {
                history.push(
                  `/ContractSub/Detail/${row.oyaseino}/${row.thino}`
                );
                setLocalStorage();
              }}
            >
              {text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: lang.PARTNER_CODE,
      dataIndex: "trhsakcd",
      align: "center",
    },
    {
      title: lang.CONTRACT_TERM,
      dataIndex: "hacymd",
      align: "center",
    },
    {
      title: lang.ARRANGEMENT_NUMBER,
      dataIndex: "thino",
      align: "center",
    },
    {
      title: lang.PRODUCT_NAME,
      dataIndex: "buhinxnm",
    },
    {
      title: lang.ORDER_AMOUNT2,
      dataIndex: "prchseAmount",
      align: "center",
      render: (text, row) => {
        return formatNumber(row.prchseAmount);
      },
    },
    {
      title: lang.SUBCONTRACTOR_TYPE,
      dataIndex: "snychscd",
      align: "center",
    },
    {
      title: lang.DATE_CHECKED,
      dataIndex: "knsymdFrom",
      align: "center",
    },
    {
      title: lang.ACCEPT_TYPE,
      dataIndex: "dnk",
      align: "center",
    },
    {
      title: lang.ACTION,
      align: "center",
      width: "90px",
      fixed: "right",
      render: (text, row) => {
        return (
          <>
            <Button
              className="buttonPC button--outline --todo-- wAuto ml20"
              onClick={() => {
                showMessageRevertConfirm(row);
              }}
            >
              <Tooltip title={lang.BACK}>
                <RollbackOutlined />
              </Tooltip>
            </Button>
          </>
        );
      },
    },
  ];

  // Event click button "Clear" then init screen
  const [currentPage, setCurrentPage] = useState("");
  const [pagingImplement, setPagingImplement] = useState({
    position: ["bottomCenter"],
    pageSize: 15,
    hideOnSinglePage: true,
  });
  const [btnclear, setBtnclear] = useState(0);

  const onChange = (pagination) => {
    setCurrentPage(pagination.current);
    setBtnclear(2);
    clearLocalStorage();
  };

  const setLocalStorage = () => {
    localStorage.setItem("Page", currentPage);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("Page");
  };

  useEffect(() => {
    let pageNum = localStorage.getItem("Page");
    if (currentPage.length === 0 || btnclear == 1) {
      setCurrentPage(1);
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(2);
    } else if (btnclear == 2) {
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(0);
    } else if (pageNum != null) {
      setPagingImplement({ ...pagingImplement, current: parseInt(pageNum) });
      setBtnclear(0);
      clearLocalStorage();
    }
  });
  //-------------------------------------------------

  // Render
  return (
    <div className="des-content">
      <TitlePage name={"下請取消一覧"} />
      <BoxContent
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 24 : "12px 24px",
        }}
      >
        <Row justify="space-between">
          <Col span={24} className="right">
            <Space size={10} align="center">
              <span style={{ fontSize: 15 }}>
                {lang.NUMBER} {grid.count} {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>

        {isPC ? (
          <Table
            rowKey={(record) => record.thino}
            columns={initColumns}
            dataSource={grid.data}
            current={currentPage}
            pagination={pagingImplement}
            onChange={onChange}
            scroll={{ x: 2370 }}
            sticky={{ offsetHeader: "54px" }}
          />
        ) : (
          []
        )}
      </BoxContent>
      <FooterPage>
        <Row justify="space-between" style={{ marginRight: "20px" }}>
          <Col span={24} className="right">
            <Space size={8} align="center">
              <Button
                className="buttonPC button--outline --todo-- wAuto ml20"
                disabled={revertButtonDeleteAll}
                onClick={() => {
                  showMessageRevertContractSubChecked(grid.data);
                }}
              >
                <RollbackOutlined />
                {" " + lang.REVERT_ALL_BUTTON}
              </Button>
            </Space>

            <Space size={20} align="center">
              <Button
                className="buttonPC button--outline --todo-- wAuto ml20"
                onClick={() => {
                  history.goBack();
                }}
              >
                <RollbackOutlined />
                {" " + COMMON_CONSTANT.BACK}
              </Button>
            </Space>
          </Col>
        </Row>
      </FooterPage>
      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default ListRemoveSubcontract;
