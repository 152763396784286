import loadable from "@loadable/component";
import React from "react";
import Role from "./roles.const";
// Role
import Role_list from "@src/page/Role_OF/list";

//machine_management
import { SettingFilled } from "@ant-design/icons";
import { lang } from "@src/constants/common.const";
import { ROUTER_PAGE } from "@src/constants/router.const";
import ContractParent_List from "@src/page/ContractParent/list";
import BusinessPartnerMaster_List from "@src/page/BusinessPartnerMaster/list";
import RepresenMaster_List from "@src/page/RepresentativeMaster/list";
import RepresenMaster_Add from "@src/page/RepresentativeMaster/add";
import ContractParentDetail from "../../page/ContractParent/detail";
import EditContractParent from "../../page/ContractParent/edit";
import ListRemoveSubcontract from "../../page/ContractSub/listRemove";
import SubContractDetail from "../../page/ContractSub/detail";
import SubContractCreate from "../../page/ContractSub/create";
//START TamNM10
import ParentContractIsDeleted from "@src/page/ContractParentIsDeleted/list";
export default [
  {
    component: ContractParent_List,
    path: "/ContractParentManagement",
    title: lang.CONTRACT_PARENT,
    exact: true,
    menuCode: "M100",
    key: "CONTRACT_PARENT",
  },

  {
    component: EditContractParent,
    path: "/EditContractParent",
    title: "List Representative Master",
    menuCode: "M100",
    exact: true,
  },

  {
    component: BusinessPartnerMaster_List,
    path: "/BusinessPartnerMaster",
    title: "List Busines Partner Master",
    menuCode: "M130",
    exact: true,
  },

  {
    component: RepresenMaster_List,
    path: "/RepresentativeMaster",
    title: "List Representative Master",
    menuCode: "M110",
    exact: true,
  },

  {
    component: RepresenMaster_Add,
    path: "/AddRepresentativeMaster",
    title: "Add Representative Master",
    menuCode: "M110",
    exact: true,
  },

  {
    component: RepresenMaster_Add,
    path: "/EditRepresentativeMaster/:id",
    title: "Edit Representative Master",
    menuCode: "M110",
    exact: true,
  },

  {
    component: Role_list,
    path: ROUTER_PAGE.ROLE_SETTING,
    title: lang.ROLE_SETTING,
    icon: <SettingFilled style={{ fontSize: "20px" }} />,
    exact: true,
    key: "ROLE_SETTING",
    permission: [Role.HEAD_OFFICE_ADMIN],
    menuCode: "M120",
  },

  {
    key: "ParentContractIsDeleted",
    component: ParentContractIsDeleted,
    path: ROUTER_PAGE.REVERTPARENTCONTRACT,
    title: lang.TITLE_DEL_PARENT_CONTRACT,
    menuCode: "M100",
    exact: true,
  },

  {
    component: ContractParentDetail,
    path: "/ContractParent/Detail",
    title: lang.REJECT_SUBCONTRACT_DETAIL,
    menuCode: "M100",
    exact: true,
  },

  {
    path: "/SubContract/List",
    menuCode: "M100",
    exact: true,
  },
  {
    component: ListRemoveSubcontract,
    path: "/ContractSub/GetListRemove",
    title: lang.REJECT_SUBCONTRACT,
    menuCode: "M100",
    exact: true,
  },
  {
    component: SubContractDetail,
    path: "/ContractSub/Detail/:oyaseino/:thino",
    title: lang.REJECT_SUBCONTRACT_DETAIL,
    menuCode: "M100",
    exact: true,
  },
  {
    component: SubContractCreate,
    path: "/ContractSub/create/:oyaseino/:thino",
    title: lang.REJECT_SUBCONTRACT_CREATE,
    menuCode: "M100",
    exact: true,
  },
  {
    path: "/ContractSub/Revert/:thino",
    menuCode: "M100",
    exact: true,
  },
  {
    path: "/ContractSub/Remove/:thino",
    menuCode: "M100",
    exact: true,
  },
  {
    path: "/ContractSub/GetStatus/:thino",
    menuCode: "M100",
    exact: true,
  },
  {
    path: "/ContractSub/RemoveChecked",
    menuCode: "M100",
    exact: true,
  },
];
