import {
  FileDoneOutlined,
  ReconciliationOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import ROUTER_PAGE from "@src/constants/router.const";
import { lang } from "./common.const";

export const listAllMenu = [

  // DungDV21
  {
    haveScreen: 1,
    subMenus: [],
    menuCode: "M100",
    menuName: "元請簿",
    displayOrder: 1,
    key: "CONTRACT_PARENT",
    icon: UnorderedListOutlined,
    path: ROUTER_PAGE.ROUTER_PAGE.CONTRACT_PARENT[0],
  },

  // TuanNA95
  {
    haveScreen: 1,
    subMenus: [],
    menuCode: "M110",
    menuName: "代表者マスター",
    displayOrder: 2,
    key: "REPRESENTATIVE_MASTER",
    icon: FileDoneOutlined,
    path: ROUTER_PAGE.ROUTER_PAGE.REPRESENTATIVE_MASTER[0],
  },

  {
    haveScreen: 1,
    subMenus: [],
    menuCode: "M120",
    menuName: lang.MANAGE_USER,
    displayOrder: 3,
    key: "ROLE_SETTING",
    icon: ReconciliationOutlined,
    path: ROUTER_PAGE.ROUTER_PAGE.ROLE_SETTING,
  },

  {
    haveScreen: 1,
    subMenus: [],
    menuCode: "M130",
    menuName: lang.BUSINESS_PARTNER_MASTER,
    displayOrder: 4,
    key: "BUSINESS_PARTNER_MASTER",
    icon: FileDoneOutlined,
    path: ROUTER_PAGE.ROUTER_PAGE.BUSINESS_PARTNER_MASTER,
  },
];
