import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { KEY, lang, COMMON_CONSTANT, STYLE } from "../../constants/common.const";
import { useHistory } from "react-router-dom";
import { CloseCircleOutlined, SaveOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { selectRowSearch } from "@src/redux/checkList";
import Loading from "../../component/Desktop/loading/loading";
import NumericInput from "../../component/InputNumber/numericInput";
import RowInput from "../../component/Desktop/rowInput3";
import {
  Col,
  Row,
  Space,
  Button,
  Input,
  Radio,
  Table,
  Select,
  Modal
} from "antd";
import API from "../../api/backend/contractSub";
import {
  MSG_CATCH,
  showMessage,
  renderOptionCanInputSearch
} from "../../constants/utils";
import classText from "../../models/control/text";

function PopupContractNumber({
  setIsModalOpenContractNumber: _setIsModalOpenContractNumber,
  show,
  listContractNumber: _listContractNumber,
  sbubcontractorName: _sbubcontractorName,
  setNewlistContractNumber: _setNewlistContractNumber,
  trhsakcdold: _trhsakcdold,
  tntkacold: _tntkacold,
  oyaseino: _oyaseino,
  thino: _thino
}) {
  const userId = JSON.parse(localStorage.getItem("uInfo")).userName || '';
  const contractNumbers = {
    id: null,
    trhsakcd: _trhsakcdold,
    tntkac: _tntkacold,
    licensennumber: "",
    newflag: 1,
    userid: userId ? userId : 'Admin',
    permitCategory: '',
    generalSpecific: '',
    eraName: '',
    inputNumber: '',
    status: 1,
    errorPermitCategory: false,
    errorGeneralSpecific: false,
    errorEraName: false,
    errorInputNumber: false
  };
  const [listTxtSubcontractor, setlistTxtSubcontractor] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const [permitCategory, setPermitCategory] = useState(new classText());
  const [generalSpecific, setGeneralSpecific] = useState(new classText());
  const [eraName, setEraName] = useState(new classText());
  const [inputNumber, setInputNumber] = useState(new classText());
  const [isEditRows, setIsEditRows] = useState(false);
  const [isDuplicateRows, setIsDuplicateRows] = useState(false);
  const [isDuplicateRows2, setIsDuplicateRows2] = useState(false);
  const [checkLengInputNumber, setCheckLengInputNumber] = useState(false);
  const permitCategoryRef = useRef(null);
  const generalSpecificRef = useRef(null);
  const eraNameRef = useRef(null);
  const inputNumberRef = useRef(null);
  const [dataDll, setDataDll] = useState();

  const setPermitCategory2 = (e, index) => {
    let newList = [...listTxtSubcontractor];
    newList[index].permitCategory = e ? e : "";
    setlistTxtSubcontractor(newList);
  }

  const setGeneralSpecific2 = (e, index) => {
    let newList = [...listTxtSubcontractor];
    newList[index].generalSpecific = e ? e : "";
    setlistTxtSubcontractor(newList);
  }

  const setEraName2 = (e, index) => {
    let newList = [...listTxtSubcontractor];
    newList[index].eraName = e ? e : "";
    setlistTxtSubcontractor(newList);
  }

  const setInputNumber2 = (e, index) => {
    let newList = [...listTxtSubcontractor];
    newList[index].inputNumber = handleChangeNumber(e, newList[index].inputNumber);
    setlistTxtSubcontractor(newList);
  }

  function handleChangeNumber(e, oldValue) {
    let result;
    const { value: inputValue } = e.target;
    const reg = /^\d*(\d*)?$/;
    if (reg.test(inputValue) || inputValue === '') {
      result = inputValue;
    } else {
      result = oldValue;
    }
    return result;
  };

  const deleteRowIndex = async (index) => {
    let newList = [...listTxtSubcontractor];
    if (newList[index].id) {
      const objSearch = { id: newList[index].id, thino: _thino };
      await API.CheckDelContractNumber({
        params: {
          ...objSearch,
        },
      })
        .then((res) => {
          if (!res || !res.data || res.data <= 0) {
            // newList.splice(index, 1);
            newList[index].status = 3;
          } else {
            showMessage(KEY.WARMING, lang.MSGDECONTRCTNUMBER);
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
    } else {
      newList[index].status = 3;
    }
    setlistTxtSubcontractor(newList);
    setIsEditRows(false);
    // const getID = document.querySelector("#content-edit-rows" + index);
    // getID.classList.add("display-none");
  }

  const editRowIndex = (index) => {
    const getID = document.querySelector("#content-edit-rows" + index);
    getID.classList.remove("display-none");

    // const permitCategoryID = document.querySelector("#permitCategory" + index);
    // const generalSpecificID = document.querySelector("#generalSpecific" + index);
    // const eraNameID = document.querySelector("#eraName" + index);
    // const inputNumberID = document.querySelector("#inputNumber" + index);
    // const getlicensenNumber = listTxtSubcontractor[index].licensennumber;
    // if (getlicensenNumber.length > 0) {
    //   const indexOfpermitCategory = getlicensenNumber.indexOf(lang.LEFTBRACKET);
    //   const indexOfgeneralSpecific = getlicensenNumber.indexOf(lang.MINUS);
    //   const indexOfEraName = getlicensenNumber.indexOf(lang.RIGHTBRACKET);
    //   const indexOfInputNumber = getlicensenNumber.indexOf(lang.NO);
    //   permitCategoryID.value = getlicensenNumber.substring(0, indexOfpermitCategory);
    //   generalSpecificID.value = getlicensenNumber.substring(indexOfpermitCategory + 1, indexOfgeneralSpecific);
    //   eraNameID.value = getlicensenNumber.substring(indexOfgeneralSpecific + 1, indexOfEraName);
    //   inputNumberID.value = getlicensenNumber.substring(indexOfInputNumber + 1, getlicensenNumber.length);
    // }

    setIsEditRows(true);
  }

  const validateSaveEditRows = (index) => {
    let check = false;
    let newList = [...listTxtSubcontractor];
    if (listTxtSubcontractor[index].permitCategory) {
      // permitCategoryID.classList.remove(STYLE.BORDER_RED);
      newList[index].errorPermitCategory = false;
    } else {
      // permitCategoryID.classList.add(STYLE.BORDER_RED);
      newList[index].errorPermitCategory = true;
      check = true;
    }

    if (listTxtSubcontractor[index].generalSpecific) {
      // generalSpecificID.classList.remove(STYLE.BORDER_RED);
      newList[index].errorGeneralSpecific = false;
    } else {
      // generalSpecificID.classList.add(STYLE.BORDER_RED);
      newList[index].errorGeneralSpecific = true;
      check = true;
    }

    if (listTxtSubcontractor[index].eraName) {
      newList[index].errorEraName = false;
      // eraNameID.classList.remove(STYLE.BORDER_RED);
    } else {
      // eraNameID.classList.add(STYLE.BORDER_RED);
      newList[index].errorEraName = true;
      check = true;
    }

    if (!listTxtSubcontractor[index].inputNumber || (listTxtSubcontractor[index].inputNumber
      && listTxtSubcontractor[index].inputNumber.length !== 6)) {
      // inputNumberID.classList.add(STYLE.BORDER_RED);
      setCheckLengInputNumber(true);
      newList[index].errorInputNumber = true;
      check = true;
    } else {
      newList[index].errorInputNumber = false;
      setCheckLengInputNumber(false);
    }
    setlistTxtSubcontractor(newList);
    return check;
  }

  const saveEditRowIndex = (index) => {
    const getID = document.querySelector("#content-edit-rows" + index);

    if (!validateSaveEditRows(index)) {
      const getLicensen = updatelicensennumber(listTxtSubcontractor[index].permitCategory,
        listTxtSubcontractor[index].generalSpecific,
        listTxtSubcontractor[index].eraName, listTxtSubcontractor[index].inputNumber);

      const checkDuplicate = checkDuplicateRow(getLicensen, listTxtSubcontractor[index].id, index);
      if (checkDuplicate > 0) {
        setIsDuplicateRows2(true);
      } else {
        setIsDuplicateRows2(false);
        let newList = [...listTxtSubcontractor];
        newList[index].licensennumber = getLicensen;
        newList[index].status = newList[index].id ? 2 : 1;
        setlistTxtSubcontractor(newList);
        setIsEditRows(false);
        getID.classList.add("display-none");
      }
    }
  }

  const addRowIndex = () => {
    if (validateSave()) {
      contractNumbers.licensennumber = updatelicensennumber(permitCategory.value, generalSpecific.value, eraName.value, inputNumber.value);
      contractNumbers.permitCategory = permitCategory.value;
      contractNumbers.generalSpecific = generalSpecific.value;
      contractNumbers.eraName = eraName.value;
      contractNumbers.inputNumber = inputNumber.value;
      contractNumbers.status = 1;
      const checkDuplicate = checkDuplicateRow(contractNumbers.licensennumber, null, -1);
      if (checkDuplicate > 0) {
        setIsDuplicateRows(true);
      } else {
        setIsDuplicateRows(false);
        setlistTxtSubcontractor(listTxtSubcontractor => [...listTxtSubcontractor, contractNumbers]);
        const checkpermitCategory = { ...permitCategory, value: '', error: false };
        const checkgeneralSpecific = { ...generalSpecific, value: '', error: false };
        const checkeraName = { ...eraName, value: '', error: false };
        const checkinputNumber = { ...inputNumber, value: '', error: false };
        setPermitCategory(checkpermitCategory);
        setGeneralSpecific(checkgeneralSpecific);
        setEraName(checkeraName);
        setInputNumber(checkinputNumber);
      }
    }
  }

  const updatelicensennumber = (ppermitCategory, pgeneralSpecific, peraName, pinputNumber) => {
    let strgeneralSpecific = '';
    if (pgeneralSpecific == lang.GENERAL) {
      strgeneralSpecific = lang.GENERAL2;
    } else {
      strgeneralSpecific = lang.SPECIAL;
    }

    let strperaName = '';
    if (peraName) {
      strperaName = peraName.substring(1, peraName.length);
    }

    const result = ppermitCategory + lang.LEFTBRACKET + strgeneralSpecific + lang.MINUS + strperaName + lang.RIGHTBRACKET
      + lang.SUBNO + pinputNumber + lang.ISSUE;
    return result;
  }

  const checkDuplicateRow = (plicensennumber, pid, pindex) => {
    const checlRowsDuplicate = listTxtSubcontractor.filter((item, index) => item.licensennumber === plicensennumber
      && index !== pindex && item.status !== 3);
    let checlRowsDuplicateOld;
    if (pid) {
      checlRowsDuplicateOld = _listContractNumber.filter((item) => item.licensennumber === plicensennumber && item.id !== pid);
    }
    const countcheck = checlRowsDuplicate.length + (checlRowsDuplicateOld ? checlRowsDuplicateOld.length : 0);
    return countcheck;
  }

  function validateSave() {
    let flagErr = false;
    let checkpermitCategory = { ...permitCategory, value: permitCategory.value ? permitCategory.value : '', error: false };
    if (checkpermitCategory.value.length === 0) {
      flagErr = true;
      // Border red
      checkpermitCategory = { ...permitCategory, value: permitCategory.value, error: true };
    }

    let checkgeneralSpecific = { ...generalSpecific, value: generalSpecific.value ? generalSpecific.value : '', error: false };
    if (checkgeneralSpecific.value.length === 0) {
      flagErr = true;
      // Border red
      checkgeneralSpecific = { ...generalSpecific, value: generalSpecific.value, error: true };
    }

    let checkeraName = { ...eraName, value: eraName.value ? eraName.value : '', error: false };
    if (checkeraName.value.length === 0) {
      flagErr = true;
      // Border red
      checkeraName = { ...eraName, value: eraName.value, error: true };
    }

    let checkinputNumber = { ...inputNumber, value: inputNumber.value ? inputNumber.value : '', error: false, message: "" };
    if (checkinputNumber.value.length === 0 || checkinputNumber.value.length !== 6) {
      flagErr = true;
      // Border red
      let msgLength = '';
      if (checkinputNumber.value.length !== 6) {
        msgLength = lang.MSGINPUTNUMBERLENGTH;
      }
      checkinputNumber = { ...inputNumber, value: inputNumber.value, error: true, message: msgLength };
    }

    // Update state
    setPermitCategory(checkpermitCategory);
    setGeneralSpecific(checkgeneralSpecific);
    setEraName(checkeraName);
    setInputNumber(checkinputNumber);
    return !flagErr;
  }

  //init
  useEffect(() => {
    if (show) {
      const checkpermitCategory = { ...permitCategory, value: '', error: false };
      const checkgeneralSpecific = { ...generalSpecific, value: '', error: false };
      const checkeraName = { ...eraName, value: '', error: false };
      const checkinputNumber = { ...inputNumber, value: '', error: false };
      setPermitCategory(checkpermitCategory);
      setGeneralSpecific(checkgeneralSpecific);
      setEraName(checkeraName);
      setInputNumber(checkinputNumber);
      setIsSave(false);
      setIsDuplicateRows(false);
      setIsDuplicateRows2(false);
      //get Dll kyokabango
      fGetkyokabango();

      if (_listContractNumber) {
        setlistTxtSubcontractor([]);
        for (let i = 0; i < _listContractNumber.length; i++) {
          const getlicensenNumber = _listContractNumber[i].licensennumber;
          if (getlicensenNumber.length > 0) {
            const indexOfpermitCategory = getlicensenNumber.indexOf(lang.LEFTBRACKET);
            const indexOfgeneralSpecific = getlicensenNumber.indexOf(lang.MINUS);
            const indexOfEraName = getlicensenNumber.indexOf(lang.RIGHTBRACKET);
            const indexOfInputNumber = getlicensenNumber.indexOf(lang.SUBNO);
            _listContractNumber[i].permitCategory = getlicensenNumber.substring(0, indexOfpermitCategory);
            const strgeneralSpecific = getlicensenNumber.substring(indexOfpermitCategory + 1, indexOfgeneralSpecific);
            if (strgeneralSpecific === lang.GENERAL2) {
              _listContractNumber[i].generalSpecific = lang.GENERAL;
            } else if (strgeneralSpecific === lang.SPECIAL) {
              _listContractNumber[i].generalSpecific = lang.IDENTIFICATION
            }
            const getEraName = getlicensenNumber.substring(indexOfgeneralSpecific + 1, indexOfEraName);
            let strEraName = getlicensenNumber.substring(indexOfgeneralSpecific + 1, indexOfEraName);
            if (getEraName === '31') {
              strEraName = 'H' + getEraName;
            } else {
              strEraName = 'R' + getEraName;
            }
            _listContractNumber[i].eraName = strEraName;
            _listContractNumber[i].inputNumber = getlicensenNumber.substring(indexOfInputNumber + 1, getlicensenNumber.length - 1);
            _listContractNumber[i].status = null;
          }
        }
        setlistTxtSubcontractor(_listContractNumber);

        setTimeout(resetPanel, 20);
      }
    }
  }, [show]);

  function resetPanel() {
    for (let i = 0; i < _listContractNumber.length; i++) {
      const getID = document.querySelector("#content-edit-rows" + i);
      if (getID) {
        getID.classList.add("display-none");
      }
    }
  }

  const fGetkyokabango = async () => {
    await API.Getkyokabango()
      .then((res) => {
        if (res.data != null) {
          setDataDll(res.data);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  }

  const onOK = async () => {
    let message_confirm = COMMON_CONSTANT.CONFIRM_REGISTER_SUBCONTRACTNUMBER;
    showConfirmMessage(message_confirm, async function () {
      setIsSave(true);
      await API.putContractNumber(listTxtSubcontractor).then(async (res) => {
        if (res.status === 409) {
          //Error
          showMessage(KEY.ERROR, MSG_CATCH());
        } else if (res.status === 401) {
          // Unauthorized
          showMessageInfo(COMMON_CONSTANT.COMMON_CONSTANT, () => { });
        } else {
          const objSearch = { id: null, trhsakcd: _trhsakcdold, tntkac: _tntkacold, oyaseino: _oyaseino };
          await API.getContractNumber({
            params: {
              ...objSearch,
            },
          })
            .then((res) => {
              if (res.data != null) {
                let newArr2 = [..._listContractNumber];
                newArr2 = res.data;
                _setNewlistContractNumber(newArr2);
                _setIsModalOpenContractNumber(false);
                showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
              }
            })
            .catch((err) => {
              showMessage(KEY.ERROR, MSG_CATCH());
            });
          // _setNewlistContractNumber(newArr);
        }
      });
    });

  };

  // Popup common
  const showConfirmMessage = (
    msg,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    let ok, cancel;
    let callBackDefault = () => {
      Modal.destroyAll();
    };

    // Callback OK exist
    if (callbackOK) {
      ok = () => {
        callbackOK();
        callBackDefault();
      };
    }
    // Callback Cancel exist
    if (callbackCancel) {
      cancel = () => {
        callbackCancel();
        callBackDefault();
      };
    }

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: msg,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: cancel || callBackDefault,
      onOk: ok || callBackDefault,
    });
  };

  const showMessageInfo = (msg, func) => {
    showMessage(KEY.INFO, msg, func);
  };

  return (
    <div className={`content-popup ` + (show ? `d-block` : `d-none`)}>
      <div className="content-body-popup">
        <div className="pb20 bold">許可番号登録</div>
        <div className="pt5 pb5 border-bottom-black">
          <span>下請人名: </span>
          <span className="blue">{_sbubcontractorName}</span>
        </div>
        {listTxtSubcontractor ? listTxtSubcontractor.map((item, index) => (
          <>
            {item.newflag && item.newflag == 1 ? (
              <>
                {item.status != 3 ? (
                  <div className="border-bottom-black">
                    <div className="h39px pt5">
                      <span>{item.licensennumber}</span>
                      <span className="fr">
                        <Space size={10} align="center">
                          <Button
                            className="buttonPC button--outline wAuto w60px min-w60px h30px"
                            onClick={(v) => { deleteRowIndex(index) }}
                          >
                            <DeleteOutlined />
                          </Button>
                        </Space>
                      </span>
                      <span className="fr mr10">
                        <Space size={10} align="center">
                          <Button
                            className="buttonPC button--outline wAuto w60px min-w60px h30px"
                            disabled={isEditRows}
                            onClick={(v) => { editRowIndex(index) }}
                          >
                            <EditOutlined />
                          </Button>
                        </Space>
                      </span>
                    </div>

                    <div id={"content-edit-rows" + index} className="display-none">
                      <div className="horizontal-Item mt5">
                        <div className="mr10">
                          <RowInput name={lang.PERMITCATEGORY} required></RowInput>
                          {/* <Input
                            id={"permitCategory" + index}
                            style={{ width: 200 }}
                            maxLength={200}
                            value={item.permitCategory}
                            onChange={(v) => {
                              setPermitCategory2(v, index)
                            }} /> */}
                          <Select
                            id={"permitCategory" + index}
                            className={item.errorPermitCategory ? STYLE.BORDER_RED : ''}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            value={item.permitCategory}
                            style={{ width: 200 }}
                            onChange={(v) => {
                              setPermitCategory2(v, index)
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toUpperCase()
                                .indexOf(input.toUpperCase()) >= 0
                            }
                          >
                            {dataDll ? (renderOptionCanInputSearch(dataDll.listPermitCategory)) : (<></>)}
                          </Select>
                        </div>
                        <div className="mr10">
                          <RowInput name={lang.GENERALSPECIFIC} required></RowInput>
                          <Select
                            style={{ width: 100 }}
                            id={"generalSpecific" + index}
                            className={item.errorGeneralSpecific ? STYLE.BORDER_RED : ''}
                            value={item.generalSpecific}
                            options={[
                              { value: lang.GENERAL, label: lang.GENERAL },
                              { value: lang.IDENTIFICATION, label: lang.IDENTIFICATION }
                            ]}
                            onChange={(v) => {
                              setGeneralSpecific2(v, index);
                            }} />
                        </div>
                        <div className="mr10">
                          <RowInput name={lang.ERANAME} required></RowInput>
                          <div>
                            <span>
                              {/* <Input
                                value={item.eraName}
                                style={{ width: 80, marginRight: 5 }}
                                id={"eraName" + index}
                                maxLength={2}
                                onChange={(v) => {
                                  setEraName2(v, index);
                                }}
                              /> */}
                              <Select
                                id={"eraName" + index}
                                allowClear
                                showSearch
                                className={item.errorEraName ? STYLE.BORDER_RED : ''}
                                optionFilterProp="children"
                                value={item.eraName}
                                style={{ width: 80, marginRight: 5 }}
                                onChange={(v) => {
                                  setEraName2(v, index);
                                }}
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toUpperCase()
                                    .indexOf(input.toUpperCase()) >= 0
                                }
                              >
                                {dataDll ? (renderOptionCanInputSearch(dataDll.listEraName)) : (<></>)}
                              </Select>
                            </span>
                            <span>{lang.SUBNO}</span>
                          </div>
                        </div>
                        <div>
                          <RowInput name={lang.INPUTNUMBER} required></RowInput>
                          <div>
                            <span>
                              <Input
                                value={item.inputNumber}
                                className={item.errorInputNumber ? STYLE.BORDER_RED : ''}
                                style={{ width: 150, marginRight: 5 }}
                                id={"inputNumber" + index}
                                maxLength={6}
                                onChange={(v) => {
                                  setInputNumber2(v, index);
                                }}
                              />
                            </span>
                            <span>{lang.ISSUE}</span>
                          </div>
                        </div>
                      </div>
                      <p></p>
                      <div className="pb5">
                        <Space size={10} align="center">
                          <Button
                            className="buttonPC button--outline wAuto w60px min-w60px"
                            onClick={(v) => { saveEditRowIndex(index) }}
                          >
                            <SaveOutlined />
                          </Button>
                        </Space>
                        {checkLengInputNumber ? (
                          <span className="red ml10">{lang.MSGINPUTNUMBERLENGTH}</span>
                        ) : (<>
                          {isDuplicateRows2 ? (
                            <span className="red ml10">{lang.MSGDUPLICATEROW}</span>
                          ) : (<></>)}
                        </>)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="border-bottom-black bg-disable pt5 pb10 display-none">
                    {item.licensennumber}
                  </div>
                )}
              </>
            ) : (
              <div className="border-bottom-black bg-disable pt5 pb10">
                {item.licensennumber}
              </div>
            )}
          </>
        )) : (
          <></>
        )}

        <div className="horizontal-Item mt5">
          <div className="mr10">
            <RowInput name={lang.PERMITCATEGORY} required></RowInput>
            {/* <Input
              value={permitCategory.value}
              ref={permitCategoryRef}
              className={permitCategory.error ? STYLE.BORDER_RED : ""}
              style={{ width: 200 }}
              maxLength={200}
              onChange={(v) => {
                setPermitCategory({
                  ...permitCategory,
                  value: v.target.value,
                });
              }} /> */}
            <Select
              allowClear
              showSearch
              className={permitCategory.error ? STYLE.BORDER_RED : ""}
              optionFilterProp="children"
              value={permitCategory.value}
              style={{ width: 200 }}
              onChange={(v) => {
                setPermitCategory({
                  ...permitCategory,
                  value: v,
                });
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(input.toUpperCase()) >= 0
              }
            >
              {dataDll ? (renderOptionCanInputSearch(dataDll.listPermitCategory)) : (<></>)}
            </Select>
          </div>

          <div className="mr10">
            <RowInput name={lang.GENERALSPECIFIC} required></RowInput>
            <Select
              style={{ width: 100 }}
              ref={generalSpecificRef}
              className={generalSpecific.error ? STYLE.BORDER_RED : ""}
              options={[
                { value: lang.GENERAL, label: lang.GENERAL },
                { value: lang.IDENTIFICATION, label: lang.IDENTIFICATION }
              ]}
              value={generalSpecific.value}
              onChange={(v) => {
                setGeneralSpecific({
                  ...generalSpecific,
                  value: v,
                });
              }} />
          </div>

          <div className="mr10">
            <RowInput name={lang.ERANAME} required></RowInput>
            <div>
              <span>
                {/* <NumericInput
                  value={eraName}
                  ref={eraNameRef}
                  className={eraName.error ? STYLE.BORDER_RED : ""}
                  maxLength={2}
                  style={{ width: 80, marginRight: 5 }}
                  onChange={setEraName} /> */}
                <Select
                  allowClear
                  showSearch
                  className={eraName.error ? STYLE.BORDER_RED : ""}
                  optionFilterProp="children"
                  value={eraName.value}
                  style={{ width: 80, marginRight: 5 }}
                  onChange={(v) => {
                    setEraName({
                      ...eraName,
                      value: v,
                    });
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(input.toUpperCase()) >= 0
                  }
                >
                  {dataDll ? (renderOptionCanInputSearch(dataDll.listEraName)) : (<></>)}
                </Select>
              </span>
              <span>{lang.SUBNO}</span>
            </div>

          </div>

          <div>
            <RowInput name={lang.INPUTNUMBER} required></RowInput>
            <div>
              <span>
                <NumericInput
                  value={inputNumber}
                  className={inputNumber.error ? STYLE.BORDER_RED : ""}
                  ref={inputNumberRef}
                  maxLength={6}
                  style={{ width: 150, marginRight: 5 }}
                  onChange={setInputNumber} />
              </span>
              <span>{lang.ISSUE}</span>
            </div>
          </div>
        </div>

        <div className="mt10">
          <Space size={10} align="center">
            <Button
              className="buttonPC button--info wAuto w80px"
              disabled={isSave}
              onClick={addRowIndex}
            >
              <PlusOutlined />
              {COMMON_CONSTANT.ADD}
            </Button>
          </Space>
          {inputNumber.error ? (
            <span className="red ml10">{inputNumber.message}</span>
          ) : (<>
            {isDuplicateRows ? (
              <span className="red ml10">{lang.MSGDUPLICATEROW}</span>
            ) : (<></>)}
          </>)}
        </div>
        <Row justify="space-between">
          <Col span={24} className="right">
            <Space size={20} align="center">
              <Button
                className="buttonPC button--info wAuto ml15"
                disabled={isSave || isEditRows}
                onClick={onOK}
              >
                <SaveOutlined />
                {COMMON_CONSTANT.SAVE}
              </Button>
            </Space>
            <Space size={20} align="center">
              <Button
                className="buttonPC button--outline --todo-- wAuto ml20 mt30"
                onClick={() => { _setIsModalOpenContractNumber(false) }}
              >
                <CloseCircleOutlined />
                {" " + lang.CANCEL}
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div >
  );
}

export default PopupContractNumber;
