import React, { useState, useEffect } from "react";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import RowInput4 from "../../component/Desktop/rowInput4";
import Loading from "../../component/Desktop/loading/loading";
import { KEY, lang, COMMON_CONSTANT } from "../../constants/common.const";
import API from "../../api/backend/contractParent";
import APIUser from "../../api/backend/auth";
import APISubContract from "../../api/backend/contractSub";
import BreakLine from "../../component/Desktop/breakLine";
import TitlePage from "../../component/Desktop/titlePage";
import { MSG_CATCH, showMessage, getAttachName } from "../../constants/utils";
import { useHistory, Link } from "react-router-dom";
import {
  DeleteOutlined,
  RollbackOutlined,
  MinusCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import FooterPage from "../../component/footer/footerPage";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Col, Row, Table, Space, Modal, Button, Tooltip, Checkbox } from "antd";
import BoxContent from "../../component/Desktop/content";
import classGrid from "../../models/control/grid";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import { formatNumber } from "../../utils/helper";
import { setSearchParam } from "../../redux/searchContract";

function ContractParentDetail({ history: historyPage }) {
  let history = useHistory();
  const dispatch = useDispatch();

  let [showLoading, setShowLoading] = useState(false);
  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  //state auth
  const { allowRoute } = useSelector((state) => state.auth);
  const { searchParam } = useSelector((state) => state.searchContract);

  // get param
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let oyaseino = params.get("oyaseino");
  let csncb1 =
    params.get("csncb1").length === 0 ? "    " : params.get("csncb1");
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  //  Grid
  const [grid, setGrid] = useState(new classGrid());

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };
  const showMessageInfo = (msg, func) => {
    showMessage(KEY.INFO, msg, func);
  };

  //declare variable check isAdmin
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUpdateLock, setIsUpdateLock] = useState(false);

  //declare varible check status contract
  const [statusContract, setStatusContract] = useState(1);

  //declare variable data grid
  const [dataDetail, setDataDetail] = useState({});

  //declare variable checked all subcontract
  const [checkAll, setCheckAll] = useState(false);

  const disabledButtonDeleteAll =
    grid.data.filter((item) => item.checked).length < 1;

  let flgAccept = true;
  const [isAccept, setIsAccept] = useState(false);
  //init
  useEffect(() => {
    if (history.location.state) {
      dispatch(setSearchParam(history.location.state.from));
    }

    init();
  }, [allowRoute]);

  const init = async() => {
    openLoadingSpinner();
    await Promise.all([ getListSubContract(false), getDetailContract(false), funcGetStatusContract(false) ]); 
    funtGetRolesOfUser();
    closeLoadingSpinner();
  };
  // get detail contract
  const getDetailContract = async (showLoading = true) => {
    if (showLoading) {
      openLoadingSpinner();
    }
    return API.detail(oyaseino, csncb1)
      .then((res) => {
        let data = res.data ? res.data : null;
        setIsAccept(data.flaG_DEL);
        if (!data.locationName) {
          data.locationName = "";
        } else {
          data.locationName = `${data.locationName.trim()}（${data.trhsakcd}）`;
        }

        if (!data.hm20_TRHSAKNM1) {
          data.hm20_TRHSAKNM1 = "";
        } else {
          data.hm20_TRHSAKNM1 = `${data.hm20_TRHSAKNM1.trim()}（${data.jyuskicd}）`;
        }
        setDataDetail(data);
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        if (showLoading) {
          closeLoadingSpinner();
        }
      });
  };

  // get list sub contract
  const getListSubContract = async (showLoading = true) => {
    // Loading
    if (showLoading) {
      openLoadingSpinner();
    }
    return APISubContract.list(oyaseino, csncb1)
      .then((res) => {
        // Update key
        let grid = res.data;
        grid = grid.map((v, i) => {
          v["rowKey"] = i;
          v.checked = false;
          return v;
        });
        setGrid({ ...grid, data: grid, count: res.data.length });
        if (res.data.length < 16) {
          window.scroll(0, 20);
        }
      })
      .catch(() => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        if (showLoading) {
          closeLoadingSpinner();
        }
      });
  };

  // fucntion get role of current user
  const funtGetRolesOfUser = () => {
    APIUser.getRolesOfUser().then((res) => {
      checkUserIsAdmin(res.data);
    });

    function checkUserIsAdmin(users) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].role === "1") {
          // role == 1 => admin
          setIsAdmin(true);
          return;
        } else {
          if (users[i].role === "0") {
            // role == 0 => update lock
            setIsUpdateLock(true);
            return;
          }
        }
      }
    }
  };

  // function get status contract
  const funcGetStatusContract = async (showLoading = true) => {
    if (showLoading) {
      openLoadingSpinner();
    }
    await API.getStatusContract(oyaseino, csncb1).then((res) => {
      setStatusContract(res.data);
    });
    if (showLoading) {
      closeLoadingSpinner();
    }
  };

  // function click button Delete subcontract : "取消"
  const showMessageDeleteSubContract = (rowData) => {
    // Action remove
    const funcRemove = async () => {
      openLoadingSpinner();
      try {
        // Close warning
        Modal.destroyAll();

        //API remove subcontract
        await APISubContract.remove(rowData.thino).then((res) => {
          if (res.status === 409) {
            //Error
            showMessage(KEY.ERROR, MSG_CATCH());
          } else if (res.status === 401) {
            // Unauthorized
            showMessageInfo(COMMON_CONSTANT.COMMON_CONSTANT, () => {});
          }
        });
        //reload list subcontract
        await getListSubContract();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.CONFIRM_DELETE,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcRemove,
    });
  };

  // function click button Accept contract
  const showMessageAcceptContract = (
    oyaseinoContract,
    csncb1Contract,
    acceptFlg
  ) => {
    // action  accept
    const funcAccept = async () => {
      openLoadingSpinner();

      try {
        // Close warning
        Modal.destroyAll();

        await API.accept(oyaseinoContract, csncb1Contract, acceptFlg);
        //reload page
        await Promise.all([ getListSubContract(false), getDetailContract(false), funcGetStatusContract(false) ]); 

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };
    //modal
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: (
        <NewLineContent
          text={
            flgAccept
              ? COMMON_CONSTANT.CONFIRM_CONTRACT_ACCEPT +
                "\n" +
                COMMON_CONSTANT.NOTE_ACCEPT
              : COMMON_CONSTANT.CONFIRM_RETURN_ACCEPT +
                "\n" +
                COMMON_CONSTANT.NOTE_RETURN_ACCEPT
          }
        />
      ),
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcAccept,
    });
  };

  // func handle click checkbox
  const hancleChangeChecked = (rowKey) => (e) => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    const index = newGrid.data.findIndex((item) => item.rowKey === rowKey);
    if (index >= 0) {
      newGrid.data[index].checked = e.target.checked;
      setGrid(newGrid);
    }
  };

  useEffect(() => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    if (checkAll) {
      newGrid.data = newGrid.data.map((item) => {
        if (item.flagDel === null || item.flagDel === "2") {
          item.checked = true;
        }
        return item;
      });
      setGrid(newGrid);
    } else {
      if (
        grid.data.filter(
          (item) => item.flagDel === null || item.flagDel === "2"
        ).length === grid.data.filter((item) => item.checked).length
      ) {
        newGrid.data = grid.data.map((item) => {
          item.checked = false;
          return item;
        });
        setGrid(newGrid);
      }
    }
  }, [checkAll]);

  useEffect(() => {
    if (
      grid.data.filter((item) => item.flagDel === null || item.flagDel === "2")
        .length
    ) {
      if (
        grid.data.filter(
          (item) => item.flagDel === null || item.flagDel === "2"
        ).length === grid.data.filter((item) => item.checked).length
      ) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
    } else {
      setCheckAll(false);
    }
  }, [grid.data.filter((item) => item.checked === true).length]);

  const showMessageDeleteContractSubChecked = (list) => {
    // action delete all
    const funcDeleteAllChecked = async () => {
      openLoadingSpinner();
      try {
        // Close warning
        Modal.destroyAll();

        let listSubContractChecked = [];
        list.forEach((element) => {
          if (element.checked) {
            listSubContractChecked.push(element.thino);
          }
        });

        //API remove all subcontract seleted
        await APISubContract.removeChecked(listSubContractChecked).then(
          (res) => {
            if (res.status === 409) {
              //Error
              showMessage(KEY.ERROR, MSG_CATCH());
            } else if (res.status === 401) {
              // Unauthorized
              showMessageInfo(COMMON_CONSTANT.COMMON_CONSTANT, () => {});
            }

            console.log(res);
          }
        );

        //reload page
        await Promise.all([ getListSubContract(false), funcGetStatusContract(false) ]); 

        //clear listSubContractChecked
        listSubContractChecked = [];

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    //modal
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.DELETE_CHECKEDBOX_CONFIRM,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDeleteAllChecked,
    });
  };

  const showMessageAceptConfirm = (rowData) => {
    // Action update
    const funcDel = async () => {
      openLoadingSpinner();

      // API Update
      try {
        // Close warning
        Modal.destroyAll();

        await API.acceptSubContract(rowData.thino, true);

        //reload page
        await Promise.all([ getListSubContract(false), funcGetStatusContract(false) ]); 

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: (
        <NewLineContent
          text={
            COMMON_CONSTANT.CONFIRM_ACCEPT_SUB +
            "\n" +
            COMMON_CONSTANT.CONFIRM_ACCEPT_SUB_NOTE
          }
        />
      ),
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  function NewLineContent(props) {
    const text = props.text;
    const newText = text.split("\n");
    return (
      <div>
        <p>{newText[0]}</p>
        <p style={{ color: "red" }}>{newText[1]}</p>
      </div>
    );
  }

  const showMessageReturnAceptConfirm = (rowData) => {
    // Action update
    const funcDel = async () => {
      openLoadingSpinner();

      // API Update
      try {
        // Close warning
        Modal.destroyAll();

        await API.acceptSubContract(rowData.thino, false);
        //reload page
        await Promise.all([ getListSubContract(false), funcGetStatusContract(false) ]); 

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: (
        <NewLineContent
          text={
            COMMON_CONSTANT.CONFIRM_RETUN_ACCEPT_SUB +
            "\n" +
            COMMON_CONSTANT.CONFIRM_RETUN_ACCEPT_SUB_NOTE
          }
        />
      ),
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  //define column table
  const initColumns = [
    {
      title: (
        <Checkbox
          disabled={
            grid.data.filter((v) => v.flagDel === null || v.flagDel === "2")
              .length === 0
          }
          checked={checkAll}
          onChange={(e) => setCheckAll(e.target.checked)}
        ></Checkbox>
      ),
      align: "center",
      dataIndex: "checked",
      fixed: "left",
      render: (text, row) => (
        <Checkbox
          checked={row.checked}
          disabled={!(row.flagDel === null || row.flagDel === "2")}
          onChange={hancleChangeChecked(row.rowKey)}
        />
      ),
      width: "50px",
    },
    {
      title: lang.SUBCONTRACTOR,
      dataIndex: "subcontractor",
      align: "left",
      width: "230px",
      fixed: "left",
      render: (text, row) => {
        return (
          <Tooltip trigger={["hover"]} title={row.hm20_TRHSAKNM1}>
            <span
              className="underline"
              onClick={() => {
                history.push(
                  `/ContractSub/Detail/${row.oyaseino}/${row.thino}`
                );
                setLocalStorage();
              }}
            >
              {text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: lang.PARTNER_CODE,
      dataIndex: "trhsakcd",
      align: "center",
    },
    {
      title: lang.CONTRACT_TERM,
      dataIndex: "hacymd",
      align: "center",
    },
    {
      title: lang.ARRANGEMENT_NUMBER,
      dataIndex: "thino",
      align: "center",
    },
    {
      title: lang.PRODUCT_NAME,
      dataIndex: "buhinxnm",
    },
    {
      title: lang.ORDER_AMOUNT2,
      dataIndex: "prchseAmount",
      align: "center",
      render: (text, row) => {
        if (row.prchseAmount) {
          return formatNumber(row.prchseAmount);
        } else {
          return "";
        }
      },
    },
    {
      title: lang.SUBCONTRACTOR_TYPE,
      dataIndex: "snychscd",
      align: "center",
    },
    {
      title: lang.DATE_CHECKED,
      dataIndex: "knsymdFrom",
      align: "center",
    },
    {
      title: lang.ACCEPT_TYPE,
      dataIndex: "dnk",
      align: "center",
    },
    isAccept === "2" || isAccept === null
      ? {
          title: lang.ACTION,
          align: "center",
          width: "160px",
          fixed: "right",
          render: (text, row) => {
            return (
              <>
                <Tooltip title={lang.HOVER_CONFIRM}>
                  {/* DungDV21 */}
                  <Button
                    lg={8}
                    md={12}
                    xs={24}
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                    style={{
                      right: "10px",
                    }}
                    onClick={() => {
                      showMessageAceptConfirm(row);
                    }}
                    hidden={
                      row.flagDel === "2" ||
                      row.flagDel === "3" ||
                      row.flagDel === "5"
                    }
                  >
                    <CheckCircleOutlined className="" />
                    確定
                  </Button>
                </Tooltip>
                <Tooltip title={lang.HOVER_CANCEL}>
                  {/* DungDV21 */}
                  <Button
                    lg={8}
                    md={12}
                    xs={24}
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                    style={{
                      right: "10px",
                    }}
                    onClick={() => {
                      showMessageReturnAceptConfirm(row);
                    }}
                    hidden={
                      row.flagDel === null ||
                      row.flagDel === "1" ||
                      row.flagDel === "4"
                    }
                  >
                    <CloseCircleOutlined className="" />
                    解除
                  </Button>
                </Tooltip>
                {/* Delete */}
                <Tooltip title={lang.HOVER_DELETE}>
                  <Button
                    className="btnDelete"
                    onClick={() => {
                      showMessageDeleteSubContract(row);
                    }}
                    hidden={row.flagDel !== "2" && row.flagDel !== null}
                  >
                    <Tooltip title={lang.DELETE}>
                      <DeleteOutlined />
                    </Tooltip>
                  </Button>
                </Tooltip>
              </>
            );
          },
        }
      : {
          width: "0",
        },
  ];
  // Event click button "Clear" then init screen
  const [currentPage, setCurrentPage] = useState("");
  const [pagingImplement, setPagingImplement] = useState({
    position: ["bottomCenter"],
    pageSize: 15,
    hideOnSinglePage: true,
  });
  const [btnclear, setBtnclear] = useState(0);
  const onChange = (pagination) => {
    setCurrentPage(pagination.current);
    setBtnclear(2);
    clearLocalStorage();
    localStorage.removeItem("Back");
  };

  const setLocalStorage = () => {
    localStorage.setItem("Name", currentPage);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("Name");
  };

  const goBack = () => {
    console.log(searchParam);
    history.push(searchParam);
  };

  useEffect(() => {
    let pageNum = localStorage.getItem("Name");
    let pageNumBack = localStorage.getItem("Back");
    console.log("back: " + pageNumBack);
    if (currentPage.length === 0 || btnclear == 1) {
      setCurrentPage(1);
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(2);
    } else if (btnclear == 2) {
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(0);
    } else if (pageNum != null) {
      if (typeof pageNumBack === "undefined" || pageNumBack == null) {
        localStorage.setItem("Back", parseInt(pageNum));
        pageNumBack = pageNum;
      }
      if (pageNumBack != pageNum) {
        pageNum = pageNumBack;
      }
      setPagingImplement({ ...pagingImplement, current: parseInt(pageNum) });
      setBtnclear(0);
      clearLocalStorage();
    }
  });
  //-------------------------------------------------

  // Render
  return (
    <div className="des-content">
      <TitlePage name={"元請詳細情報"} />

      <BoxContent
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 0 : "12px 24px",
        }}
      >
        {/* detail contract */}
        <div>
          <div
            className="title-page d-block left border-bottom-10px-orange pl-20 pr-20"
            style={{ marginBottom: "10px" }}
          >
            <h1 className="bold white-space">{lang.CONTRACT_DETAIL}</h1>
          </div>

          <GroupItem
            center
            style={{
              margin: isPC ? "24px 16px" : 0,
              padding: isPC ? 0 : "12px 24px",
            }}
          >
            <BreakLine />
            <RowInput name={lang.OFFICE_NAME} box>
              {dataDetail.officeName}
            </RowInput>

            <BreakLine />

            <RowInput4
              name={lang.DEPARTMENT}
              checkboxStatus={!dataDetail.allowSyncDepartmentName}
              box
            >
              {dataDetail.hm17_BUNM}
            </RowInput4>

            <BreakLine />

            <RowInput name={lang.REPRESENTATIVE_NAME} box>
              <Row justify="space-between">
                <Col span={10}>{dataDetail.representativeName}</Col>
                <Col span={14} className="right">
                  <Button
                    className="btnNav"
                    onClick={() => {
                      history.push(`/RepresentativeMaster`);
                    }}
                  >
                    {lang.REPRESENTATIVE_CONTRACT}
                  </Button>
                </Col>
              </Row>
            </RowInput>

            <BreakLine />

            <RowInput name={lang.MADE} box>
              {dataDetail.oyaseino}
            </RowInput>

            <BreakLine />

            <RowInput4
              name={lang.CONSTRUCTION}
              checkboxStatus={!dataDetail.allowSyncConstructionName}
              box
            >
              {dataDetail.kjiryknm}
            </RowInput4>

            <BreakLine />
            <RowInput4
              name={lang.LOCATION_NAME}
              checkboxStatus={!dataDetail.allowSyncLocationName}
              box
            >
              {dataDetail.locationName}
            </RowInput4>

            <BreakLine />
            <RowInput name={lang.LOCATION_PROVINE} box>
              {dataDetail.hm20_TRHADRS2}
            </RowInput>

            <BreakLine />

            <RowInput name={lang.LOCATION_DISTRICT} box>
              {dataDetail.hm20_TRHADRS3}
            </RowInput>

            <BreakLine />

            <RowInput4
              name={lang.CONTRACT_TERM}
              checkboxStatus={!dataDetail.allowSyncContractDate}
              box
            >
              {dataDetail.display_JYUYMD}
            </RowInput4>

            <BreakLine />

            <RowInput4
              name={lang.CUSTOMER_NAME}
              checkboxStatus={!dataDetail.allowSyncOrdererName}
              box
            >
              {dataDetail.hm20_TRHSAKNM1}
            </RowInput4>

            <BreakLine />

            <RowInput name={lang.CUSTOMER_ADDRESS} box>
              {dataDetail.hm20_TRHADRS1}
            </RowInput>

            <BreakLine />

            <RowInput4
              name={lang.DATE_FINSHED_CHECK}
              checkboxStatus={!dataDetail.allowSyncFinishedDate}
              box
            >
              {dataDetail.display_KYKNKIYMD}
            </RowInput4>

            <BreakLine />
            <RowInput4
              name={lang.ORDER_AMOUNT}
              checkboxStatus={!dataDetail.allowSyncOrderAmount}
              box
            >
              {dataDetail.jyuznkkg != null &&
                `${formatNumber(dataDetail.jyuznkkg)}（千円）（税抜）`}
            </RowInput4>

            <BreakLine />
            <RowInput name={lang.ATTACHED_DOCUMENT} box>
              {dataDetail.flgShowAttach !== "0" ? (
                <Space size={8} align="center">
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {dataDetail.attached_document &&
                      dataDetail.attached_document.split(",").map((item) => (
                        <li>
                          <a href={item} download>
                            {getAttachName(
                              item.split("/")[item.split("/").length - 1],
                              0
                            )}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Space>
              ) : null}
            </RowInput>

            <BreakLine />
          </GroupItem>
        </div>

        {/* list sub contract */}
        {console.log("isAdmin", isAdmin)}
        {console.log("dataDetail.flagDel", dataDetail.flagDel)}
        {/* <div> */}
        <div
          className="title-page d-block left border-bottom-10px-orange pl-20 pr-20"
          style={{ marginBottom: "10px" }}
        >
          <h1 className="bold white-space">{lang.REJECT_SUBCONTRACT}</h1>
        </div>
        <BoxContent
          style={{
            margin: isPC ? "24px 16px" : 0,
            padding: isPC ? 24 : "12px 24px",
          }}
        >
          <Row justify="space-between">
            <Col span={12}>
              {((dataDetail.flgLock !== "0" && !isUpdateLock) ||
                isUpdateLock ||
                isAdmin) &&
              (isAccept === "2" || isAccept === null) ? (
                <Button
                  type="link"
                  className="btnAddRecord mb15"
                  disabled={dataDetail.flagDel && dataDetail.flagDel != "2"}
                  onClick={() => {
                    history.push(`/ContractSub/create/${oyaseino}/${"blank"}`);
                  }}
                >
                  <AddIcon />
                  {lang.ADD_SUB_CONTRACT}
                </Button>
              ) : null}
            </Col>
            <Col span={12} className="right">
              <Space size={10} align="center">
                <span style={{ fontSize: 15 }}>
                  {lang.ACCESS_NUMBERS} {grid.count} {lang.CASE}
                </span>
              </Space>
            </Col>
          </Row>
          {isPC ? (
            <Table
              rowKey={(record) => record.thino}
              columns={initColumns}
              dataSource={grid.data}
              current={currentPage}
              pagination={pagingImplement}
              onChange={onChange}
              scroll={{ x: 2370 }}
              sticky={
                grid.data.length < 3
                  ? {}
                  : { offsetHeader: "54px", offsetScroll: "55px" }
              }
            />
          ) : (
            []
          )}
        </BoxContent>
      </BoxContent>
      <FooterPage>
        <Row
          justify="space-between"
          style={{ float: "right", marginRight: "20px" }}
        >
          <Col span={24}>
            {((dataDetail.flgLock !== "0" && !isUpdateLock) ||
              isUpdateLock ||
              isAdmin) &&
            (isAccept === "2" || isAccept === null) ? (
              <Space size={8} align="center">
                <Button
                  className="buttonPC button--info wAuto ml15"
                  onClick={() => {
                    history.push(
                      `/EditContractParent?oyaseino=${oyaseino}&&csncb1=${csncb1}`
                    );
                  }}
                >
                  <EditOutlined />
                  {lang.EDIT_CONTRACT_BUTTON}
                </Button>
              </Space>
            ) : null}

            <Space size={8} align="center">
              <Button
                className="buttonPC button--outline --todo-- wAuto ml20"
                disabled={disabledButtonDeleteAll}
                hidden={statusContract === 1 || statusContract === 3}
                onClick={() => {
                  showMessageDeleteContractSubChecked(grid.data);
                }}
              >
                <DeleteOutlined />
                {" " + lang.DELETE_ALL_BUTTON}
              </Button>
            </Space>

            <Space size={8} align="center">
              <Button
                className="buttonPC button--info wAuto ml15"
                hidden={statusContract === 1 || statusContract === 3}
                onClick={setLocalStorage}
              >
                <Link
                  to={`../ContractSub/GetListRemove?oyaseino=${oyaseino}&&csncb1=${csncb1}`}
                >
                  <MinusCircleOutlined />
                  {" " + lang.LIST_DELETE}
                </Link>
              </Button>
            </Space>

            <Space size={8} align="center">
              {isAccept === "2" || isAccept === null ? (
                <Button
                  className="buttonPC button--info wAuto ml15"
                  onClick={() => {
                    flgAccept = true;
                    showMessageAcceptContract(oyaseino, csncb1, true);
                  }}
                  hidden={isAccept === "2"}
                >
                  <Tooltip title={lang.ACCEPT}>
                    <CheckCircleOutlined />
                    {" " + lang.BUTTON_MAKE_SURE}
                  </Tooltip>
                </Button>
              ) : null}
            </Space>

            <Space size={8} align="center">
              {isAccept === "2" || isAccept === null ? (
                <Button
                  className="buttonPC button--info wAuto ml15"
                  onClick={() => {
                    flgAccept = false;
                    showMessageAcceptContract(oyaseino, csncb1, false);
                  }}
                  hidden={isAccept === null}
                >
                  <Tooltip title={lang.HOVER_CANCEL}>
                    <CloseCircleOutlined className="" />
                    {" " + lang.HOVER_CANCEL}
                  </Tooltip>
                </Button>
              ) : null}
            </Space>

            <Space size={8} align="center">
              <Button
                className="buttonPC button--outline --todo-- wAuto ml20"
                onClick={goBack}
              >
                <RollbackOutlined />
                {" " + COMMON_CONSTANT.BACK}
              </Button>
            </Space>
          </Col>
        </Row>
      </FooterPage>
      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default ContractParentDetail;
