import React, { useState, useEffect } from "react";
import {
  Input,
  Tooltip
} from "antd";

function NumericInput({
  maxLength: _maxLength,
  onChange: _onChange,
  value: _value,
  style: _style,
  className: _className,
  ref: _ref,
  id: _id
}) {

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\d*)?$/;
    if (reg.test(inputValue) || inputValue === '') {
      _onChange({
        ..._value,
        value: inputValue,
      });
    } else {
      _onChange({
        ..._value,
        value: _value.value ? _value.value : '',
      });
    }
  };

  // '.' at the end or only '-' in the input box.
  // const handleBlur = () => {
  //   if (_value.value) {
  //     let valueTemp = _value.value;
  //     if (_value.value.charAt(_value.value.length - 1) === '.' || _value.value === '-') {
  //       valueTemp = _value.value.slice(0, -1);
  //     }
  //     _onChange({
  //       ..._value,
  //       value: valueTemp.replace(/0*(\d+)/, '$1'),
  //     });
  //   }
  // };

  return (
    <Input
      onChange={handleChange}
      ref={_ref}
      value={_value.value}
      maxLength={_maxLength}
      style={_style}
      className={_className}
      id={_id}
    />
  );
}

export default NumericInput;
