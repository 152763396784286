/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Space,
  Input,
  Button,
  Layout,
  Modal,
  Tooltip,
  Form,
} from "antd";
import "./popupCss.css";
import FooterPage from "../../component/footer/footerPage";
import PopupAllUser from "./popupAllUser";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import { useMediaQuery } from "react-responsive";
import {
  COMMON_CONSTANT,
  lang,
  KEY,
  CONFIG,
  LIMIT,
} from "../../constants/common.const";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import API from "../../api/backend/managementRole";
import {
  MSG_CATCH,
  statusRes,
  showMessage,
  isHeadOffice,
  isAdminOffice,
  isUpdateLock,
} from "../../constants/utils";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";

import {
  SearchOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
  UndoOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import classPopup from "../../models/control/popup";
import { checkRoleScreen } from "../../utils/roles";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const { Content } = Layout;
function Role_list() {
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [initPage, setInitPage] = useState(true);
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  const [txtUsername, settxtUsername] = useState(null);
  const [txtFullname, settxtFullname] = useState(null);
  const [txtShiID, settxtShiID] = useState(null);
  const [listUserName, setListUserName] = useState([]);
  // POPUP
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [popupChecklist, setPopupChecklist] = useState(new classPopup());
  const [popupRole, setPopupRole] = useState(new classPopup());
  const [form] = Form.useForm();
  const [gridPopup, setGridPopup] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  const [_listChecklist, set_listChecklist] = useState([]);
  const [_nameChecklist, set_nameChecklist] = useState(new classText());
  const [loadSpin, setLoadSpin] = useState(true);
  const location = useLocation();
  const { allowRoute } = useSelector((state) => state.auth);
  // Search
  const search_onClick = async (init, saveflg = false) => {
    onClickClear();
    // Loading
    openLoadingSpinner();
    //Call
    return await API.searchUserRole(
      txtUsername && init !== true ? txtUsername.trim() : "",
      txtFullname && init !== true ? txtFullname.trim() : "",
      txtShiID && init !== true ? txtShiID.trim() : ""
    )
      .then((res) => {
        if (statusRes(res)) {
          let newGrid = res.data;
          if (
            listUserName.length <= 0 ||
            (txtUsername === null && txtFullname === null && txtShiID === null)
          ) {
            setListUserName([]);
            newGrid.data.map((v) => {
              setListUserName((listUserName) => [...listUserName, v.userName]);
              return v;
            });
          }
          setGrid(newGrid);
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        !init && showMessageError(MSG_CATCH());
      })
      .then(() => {
        setInitPage(false);
        // Close loading
        closeLoadingSpinner();
        if (saveflg === true) {
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
        }
      });
  };
  //onchange ddl
  const changeddl = (username) => (e) => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    const index = newGrid.data.findIndex((item) => item.userName === username);
    if (index >= 0) {
      newGrid.data[index].role = e.target.value;
      if (newGrid.data[index].isChange !== 3) {
        newGrid.data[index].isChange = 2;
      }
      setGrid(newGrid);
    }
  };
  // Column GRID
  const initColumns = [
    {
      title: lang.FULL_NAME,
      dataIndex: "displayName",
      align: "left",
      width: "25%",
    },
    {
      title: "SHI-ID",
      width: "30%",
      align: "left",
      render: (row) => {
        return <b>{row.email}</b>;
      },
    },
    {
      title: lang.ROLE_SETTING,
      align: "center",
      width: "20%",
      render: (text, row) => {
        return (
          <select
            className="dropdown"
            style={{ minWidth: "35%", height: "35px", fontWeight: "bold" }}
            value={row.role}
            onChange={changeddl(row.userName)}
          >
            <option value="1"> 管理者</option>
            <option value="2"> 利用者</option>
            <option value="0"> 更新ロック</option>
          </select>
        );
      },
    },
    {
      title: lang.ACTION,
      align: "center",
      width: "10%",
      render: (row) => (
        <Tooltip title={lang.DELETE1}>
          {/* Delete */}
          <Button
            disabled={!row.canDelete}
            className="mr5 btnDelete"
            onClick={() => {
              showMessageDeleteConfirm(row.userName);
            }}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const showMessageDeleteConfirm = (username) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();
      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        let newGrid = grid;
        const index = newGrid.data.findIndex(
          (item) => item.userName === username
        );
        let username1 = newGrid.data[index].userName;
        if (newGrid.data[index].isChange === 3) {
          newGrid.data.splice(index, 1);
        } else {
          newGrid.data[index].isChange = 1;
        }
        newGrid.count = newGrid.data.filter((v) => v.isChange !== 1).length;
        listUserName.splice(listUserName.indexOf(username1), 1);
        await setGrid(newGrid);
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage("KEY.ERROR", MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      title: lang.CONFIRM,
      centered: true,
      content: COMMON_CONSTANT.CONFIRM_DEL,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  const showMessageResetConfirm = () => {
    // Action del
    Modal.confirm({
      title: lang.CONFIRM,
      centered: true,
      content: COMMON_CONSTANT.RESET,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: onReset,
    });
  };
  const showMessageSaveConfirm = () => {
    // Action del
    Modal.confirm({
      title: lang.CONFIRM,
      centered: true,
      content: COMMON_CONSTANT.CONFIRM_SAVE,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: onSave,
    });
  };
  const objectClone = (obj = {}) => JSON.parse(JSON.stringify(obj));
  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Cancel
  const onReset = () => {
    Modal.destroyAll();
    settxtUsername("");
    settxtFullname("");
    settxtShiID("");
    search_onClick(true);
  };
  // SAVE
  const onSave = () => {
    // Loading
    openLoadingSpinner();
    // Close warning
    Modal.destroyAll();
    let dataSent = objectClone(grid);
    dataSent.data = dataSent.data.filter((v) => v.isChange !== 0); // Only user update role
    if (dataSent.data.length === 0) {
      showMessage(
        KEY.WARMING,
        "情報が変わりません。もう一度確認してください。"
      );

      closeLoadingSpinner();
      return;
    }

    // Call
    return API.Save(dataSent.data)
      .then((res) => {
        if (statusRes(res)) {
          if (res.data === 0) {
            search_onClick(false, true);
          } else {
            showMessageError(MSG_CATCH());
          }
        }
      })
      .catch((error) => {
        console.log(error);
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        setInitPage(false);
      });
  };

  // POPUP
  const popup = {
    show: () => {
      setPopupChecklist({ ...popupChecklist, show: true });
    },
    hide: () => {
      setPopupChecklist({ ...popupChecklist, show: false });
    },
    showPopup: () => {
      // Clear
      form.resetFields();
      // Re-search
      popup.search();
      popup.show();
    },
    closePopup: () => {
      // Clear
      form.resetFields();
      set_listChecklist([]);
      set_nameChecklist(new classText());
      popup.hide();
    },
    // Search list Checklist
    search: async (reSearch) => {
      // Loading
      setLoadSpin(true);
      return API.searchUserAD({
        // username: getUsername(),
        fullname: getFullname(),
        shiID: getShiID(),
        offset: reSearch ? 0 : gridPopup.offset,
        limit: LIMIT,
      })
        .then((res) => {
          const data = res.data;
          set_listChecklist(popup.verifyData(data.data));
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          setLoadSpin(false);
        });
    },

    verifyData: (dataNew) => {
      return dataNew.map((v) => {
        let checked = false;
        listUserName.forEach((username) => {
          if (username === v.userPrincipalName) {
            checked = true;
          }
        });
        v["disabled"] = checked ? true : v.mail == null ? true : false;
        v["checked"] = v["checked"] || checked; // Keep checked - user
        return v;
      });
    },
    addToGroup: () => {
      openLoadingSpinner();
      let newGrid = grid;
      let listAdd = [];
      // List add
      _listChecklist
        .filter((v) => v.checked && !v.disabled)
        .map((v) => {
          const userArr = v.mail.split("@");
          let user = {
            userName: userArr[0],
            displayName: v.displayName,
            canDelete: true,
            email: v.mail !== undefined ? v.mail : null,
            isChange: 3,
            role: "2",
            siteOfficeMasterCode: null,
          };
          listUserName.push(v.userPrincipalName);
          listAdd.push(userArr[0]);
          newGrid.data.unshift(user);
          return v;
        });
      newGrid.count = newGrid.data.length;
      setGrid(newGrid);
      showMessage(
        KEY.INFO,
        <>
          {listAdd.map((v) => {
            return (
              <>
                {v} <br />
              </>
            );
          })}
          {COMMON_CONSTANT.BOM_C003}
        </>
      );
      closeLoadingSpinner();
      popup.closePopup();
    },
  };

  // POPUP Role
  const popup_Role = {
    show: () => {
      setPopupRole({ ...popupRole, show: true });
    },
    hide: () => {
      setPopupRole({ ...popupRole, show: false });
    },
    showPopup: () => {
      popup_Role.show();
    },
    closePopup: () => {
      popup_Role.hide();
    },
  };

  // Update offset
  useEffect(() => {
    setGridPopup({ ...gridPopup, offset: _listChecklist.length });
  }, [_listChecklist]);
  // Search for Mobile
  useEffect(() => {
    !isPC && search_onClick(false);
  }, [txtUsername]);
  useEffect(() => {
    !isPC && search_onClick(false);
  }, [txtFullname]);
  useEffect(() => {
    !isPC && search_onClick(false);
  }, [txtShiID]);

  const getFullname = () => {
    return _nameChecklist.full_name && _nameChecklist.full_name.length > 0
      ? _nameChecklist.full_name.trim()
      : null;
  };
  const getShiID = () => {
    return _nameChecklist.shi_id && _nameChecklist.shi_id.length > 0
      ? _nameChecklist.shi_id.trim()
      : null;
  };

  const gridLoadMore = () => {
    API.searchUserAD({
      // username: getUsername(),
      fullname: getFullname(),
      shiID: getShiID(),
      offset: gridPopup.offset,
      limit: LIMIT,
    })
      .then((res) => {
        const data = res.data;
        // push

        set_listChecklist(popup.verifyData([..._listChecklist, ...data.data]));
      })
      .catch((error) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  const grid_checkbox = (id, index, checked) => {
    const list = JSON.parse(JSON.stringify(_listChecklist));
    list[index]["checked"] = checked;
    set_listChecklist(list);
  };
  //setting disabled button save on popup user screen
  const [disabledBtSavePop, setDisabledBtSavePop] = useState(true);
  useEffect(() => {
    if (_listChecklist.filter((v) => v.checked && !v.disabled).length > 0) {
      setDisabledBtSavePop(false);
    } else {
      setDisabledBtSavePop(true);
    }
  }, [_listChecklist.filter((v) => v.checked && !v.disabled)]);

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      // Open loading
      openLoadingSpinner();
      setPopupChecklist({
        ...popupChecklist,
        show: false,
        title: lang.SEE_ALL,
      });
      setPopupRole({
        ...popupRole,
        show: false,
        title: COMMON_CONSTANT.BUTTON_ROLE,
      });
      // Load pulldown
      Promise.all([search_onClick(true)])
        .then((res) => { })
        .catch((err) => {
          showMessageError(MSG_CATCH());
        })
        .then(() => {
          closeLoadingSpinner();
        });
    }
  }, [allowRoute]);

  // Event click button "Clear" then init screen
  const [currentPage, setCurrentPage] = useState("");
  const [pagingImplement, setPagingImplement] = useState({
    position: ["bottomCenter"],
    pageSize: 15,
    hideOnSinglePage: true,
  });
  const [btnclear, setBtnclear] = useState(0);
  const onClickClear = () => {
    setBtnclear(1);
  };
  const onChange = (pagination) => {
    setCurrentPage(pagination.current);
    setBtnclear(2);
  };

  useEffect(() => {
    if (currentPage.length === 0 || btnclear == 1) {
      setCurrentPage(1);
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(2);
    } else if (btnclear == 2) {
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(0);
    }
  });
  //-------------------------------------------------

  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.MANAGE_USER} />
      <Content
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        {/* Group input */}
        <Form
          name="searchRole"
          layout={"vertical"}
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          <Row gutter={[8, isPC ? 0 : 16]}>
            <Col span={6}>
              <Input
                placeholder={lang.FULL_NAME}
                maxLength={255}
                value={txtFullname}
                onChange={async (e) => {
                  settxtFullname(e.target.value);
                }}
              />
            </Col>
            <Col span={6} offset={1}>
              <Input
                placeholder={lang.SHI_ID}
                maxLength={255}
                value={txtShiID}
                onChange={async (e) => {
                  settxtShiID(e.target.value);
                }}
              />
            </Col>
            <Col offset={1}>
              {isPC && (
                <Form.Item>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={search_onClick}
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Content>

      {!initPage && (
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
          }}
        >
          <Row justify="space-between mb15">
            <Col span={12}>
              {/* Add */}
              {(isHeadOffice() || isAdminOffice() || isUpdateLock()) && (
                <span className="fl mr20">
                  <Button
                    type="link"
                    className="pl0 pr0 m-wAuto btnLink"
                    onClick={() => {
                      popup.showPopup();
                    }}
                  >
                    <AddIcon className="svg-default mr5" />
                    <span style={{ fontWeight: 600 }}>{COMMON_CONSTANT.ADD}</span>
                  </Button>
                </span>
              )}
              <span className="fl">
                <Button
                  type="link"
                  className="pl0 pr0 m-wAuto btnLink"
                  onClick={() => {
                    popup_Role.showPopup();
                  }}
                >
                  <span style={{ fontWeight: 600 }}>{COMMON_CONSTANT.BUTTON_ROLE}</span>
                </Button>
              </span>
            </Col>
            <Col span={12} className="right">
              {/* Count */}
              <Space size={10} align="center ">
                {lang.ACCESS_NUMBERS}
                <span>
                  {grid.count}
                  {lang.CASE}
                </span>
              </Space>
            </Col>
          </Row>

          {/* Grid  */}
          <Table
            className="tblRole mt10"
            columns={initColumns}
            dataSource={
              grid.data.length === 0
                ? null
                : grid.data.filter((v) => v.isChange !== 1)
            }
            current={currentPage}
            pagination={pagingImplement}
            onChange={onChange}
            sticky={{ offsetHeader: "53px" }}
          />
        </Content>
      )}

      {/* Footer */}
      {grid.data.length > 0 && (
        <FooterPage>
          <Row style={{ float: "right", marginRight: "20px" }}>
            <Col>
              <Button
                className="buttonPC button--info wAuto "
                onClick={showMessageSaveConfirm}
              >
                <SaveOutlined />
                {lang.SAVE}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10"
                onClick={showMessageResetConfirm}
              >
                <UndoOutlined />
                {lang.RESET}
              </Button>
            </Col>
          </Row>
        </FooterPage>
      )}

      <Modal
        className={"popupP2"}
        centered
        title={popupChecklist.title}
        visible={popupChecklist.show}
        onCancel={popup.closePopup}
        width={1000}
        align="left"
        footer={[
          <Button
            key="submit"
            type="primary"
            disabled={disabledBtSavePop}
            onClick={popup.addToGroup}
          >
            <PlusOutlined />
            {COMMON_CONSTANT.ADD}
          </Button>,
          <Button key="back" onClick={popup.closePopup}>
            <CloseOutlined />
            {lang.CANCEL}
          </Button>,
        ]}
      >
        <PopupAllUser
          isPC={isPC}
          loadSpin={loadSpin}
          popup={popup}
          _nameChecklist={_nameChecklist}
          set_nameChecklist={set_nameChecklist}
          gridPopup={gridPopup}
          _listChecklist={_listChecklist}
          form={form}
          gridLoadMore={gridLoadMore}
          grid_checkbox={grid_checkbox}
        />
      </Modal>
      <Modal
        className={"popupP2"}
        centered
        title={popupRole.title}
        visible={popupRole.show}
        onCancel={popup_Role.closePopup}
        width={1000}
        align="left"
        footer={[
          <Button key="back" onClick={popup_Role.closePopup}>
            <CloseOutlined />
            {lang.CANCEL}
          </Button>,
        ]}
      >
        <table class="table-Role">
          <tr>
            <th className="w200px">ユーザー権限</th>
            <th className="w300px">画面アクセス</th>
            <th>内容</th>
          </tr>
          <tr>
            <td className="bold">利用者</td>
            <td>
              <ul>
                <li>元請簿</li>
                <li>取引先マスター</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>更新</li>
                <li>登録</li>
                <li>確定</li>
                <li>削除</li>
                <li>復旧</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="bold">更新ロック</td>
            <td>
              <ul>
                <li>元請簿</li>
                <li>取引先マスター</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>更新</li>
                <li>登録</li>
                <li>確定</li>
                <li>削除</li>
                <li>復旧</li>
                <li>元請、取引先マスター、ロックされている元請、下請を更新できる。</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="bold">管理者</td>
            <td>
              <ul>
                <li>元請簿</li>
                <li>取引先マスター</li>
                <li>ユーザー管理</li>
                <li>代表者マスター</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>すべて機能</li>
              </ul>
            </td>
          </tr>
        </table>
      </Modal>

      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default Role_list;
