import Api from "@src/api";

export default {
  // searchUserRole
  searchUserRole(username,fullname,shiID) {
    return Api().get(`UserRole?username=${username}&fullname=${fullname}&shiID=${shiID}`);
  },

  // update Shortcut
  Save(data) {
    return Api().post(`UserRole/Save`, data);
  },

  // update Shortcut
  deleteUser(username) {
    return Api().delete(`/UserRole/Users/${username}`);
  },

  // all User AD
  searchUserAD(data) {
    return Api().get(`UserRole/SearchUsers`, { params: data });
  },

  // Add User AD
  addUserAD(data) {
    return Api().post(`UserRole/AddUsers`, data);
  },
};
