class classText {
  constructor(
    user_name = "",
    full_name ="",
    shi_id ="",
    disabled = false,
    error = false,
    message = "",
    show,
    picker,
    format,
    users = [],
    list = [],
    init
  ) {
    this.user_name = user_name;
    this.full_name = full_name;
    this.shi_id = shi_id;
    this.disabled = disabled;
    this.message = message;
    this.error = error;
    this.show = show;
    this.picker = picker;
    this.format = format;
    this.users = users;
    this.list = list;
    this.init = init;
  }
}
export default classText;
